// React
import { useContext, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

// assets
import MainDrawer from "../Drawer";
import { UserContext } from "../../auth/userContext";
import withAuth from "../../auth/withAuth";
import { useAppContext } from "../../auth/appContext";
import { GetInstanceRouter } from "./Instances";
import { saveAnalytics } from "../../helpers/Analytics";
import PasswordModal from "../PasswordModal";

const RouterComponent = () => {
	const { AppSettings } = useAppContext();
	const { user } = useContext(UserContext);

	useEffect(() => {
		if (AppSettings.Name && user.UID) {
			saveAnalytics(AppSettings.Name, user.UID, "login");
		}
	}, [AppSettings, user]);

	return (
		<BrowserRouter>
			{user.logged && (<MainDrawer routes={GetInstanceRouter(AppSettings.Name)} />)}
			{/* POR CAMBIAR, VERIFICAR SI SOLO EN DHL2 O EN TODAS LAS INSTANCIAS */}
			{AppSettings.Name === 'DHL2' && <PasswordModal />}
		</BrowserRouter>
	);
};

export default withAuth(RouterComponent);
