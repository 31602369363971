import { Fade, Grid, Typography } from "@mui/material";

const Sorry: React.FC = () => {
    return (
        <Fade in={true} timeout={400}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography  variant="h6" fontWeight="bold">
                        Lo sentimos!
                    </Typography>
                    <Typography>
                        La reta a la que intentas ingresar ya ha comenzado.
                    </Typography>
                </Grid>
            </Grid>
        </Fade>
    )
}
export default Sorry;