import { format, isDate } from "date-fns";

/**
 * Regresa una fecha con formato yyyy-MM-dd
 * @param timeStamp
 * @returns
 */
export const displayShortDate = (timeStamp: string) => {
  const date = new Date(timeStamp);
  if (isDate(date)) return format(date, "yyyy-MM-dd");
  else return "Fecha inválida";
};

/**
 * Regresa una fecha con formato dd-MM-yyyy
 * @param timeStamp
 * @returns
 */
export const displayOtherDate = (timeStamp: string) => {
  const date = new Date(timeStamp);
  if (isDate(date)) return format(date, "dd-MM-yyyy");
  else return "Fecha inválida";
};
