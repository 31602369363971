import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/system";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    MuiDrawer: {
      border: "none !important",
    },
    listItem: {
      borderRadius: "100px 0 0 100px !important",
      marginTop: "8px !important",
      backgroundColor: "transparent !important",
      color: "#ffffff",
    },
    listItemSelected: {
      borderRadius: "100px 0 0 100px !important",
      marginTop: "8px !important",
      backgroundColor: "#f2f2f2 !important",
      color: "#000000",
    },
  })
);

export default useStyles;
