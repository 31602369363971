import { User as FBUser, signOut } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  setDoc,
  QuerySnapshot,
  DocumentData,
  limit,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { firestore, fbAuth, fbFunctions, db } from "../../../firebase";
import { saveAnalytics } from "../../../helpers/Analytics";
import { User } from "../../../interfaces";
import { AssignmentV2 } from "../../../interfaces/assignment";
import { State } from "../../../interfaces/context";
import { Course } from "../../../interfaces/course";
import { StudyPlan, StudyPlanAssignment } from "../../../interfaces/studyplans";
import { Courses, StudyPlans } from "../../../interfaces/ui";
import { child, get, orderByChild, ref, query as rtQuery } from "firebase/database";

export const GetUser_Default = async (instance: string, auth: FBUser, state: State, domains: string[]) => {
  if (auth.email) {
    if (domains.includes(auth.email.split("@")[1])) {
      const uid = auth.uid;

      /* 
        Obtener el usuario, consultar la instancia.
        Ahorro de datos si existe en el contexto
      */
      let data = state.user?.Name ? state : await getData(instance, uid, auth);

      return { user: data.user, state: data };
    } else {
      console.log("Domain not authorized");
      await signOut(fbAuth);
      return undefined;
    }
  }
};

interface data {
  [fecha: string]: string;
}

const getData = async (instance: string, uid: string, auth: FBUser) => {
  //Sacar el objeto User
  let user: User = {};
  const refUsers = collection(firestore, `Instances/${instance}/Users`);
  const docRef = doc(refUsers, `${uid}`);
  // console.log(uid);
  
  try {
    let snapshot = await getDoc(docRef);
    if (!snapshot.exists()) {
      //El comportamiento default será que si no existe el usuario, se crea automáticamente en BD
      const access = ["student"];
      user = {
        UID: uid,
        Name: auth.displayName ?? "Unknown user",
        Email: auth.email ?? "Unknown email",
        Access: access,
        Provider:
          auth.providerData && auth.providerData.length > 0
            ? //Poner el provider de terceros, si es que hay
            auth.providerData[0].providerId
            : //Si no... poner el base (default: firebase?)
            auth.providerId,
        Data: {},
      };

      await setDoc(docRef, user);

      const claim = httpsCallable(fbFunctions, "claim");
      await claim({ instance: instance });
      await auth.getIdToken(true);
      console.log("token refreshed");
    } else {
      user = snapshot.data() as User;
    }
  } catch (e) {
    console.error("error cachado", e);
  }

  //Sacar las asignaciones del usuario
  let snap: QuerySnapshot<DocumentData> | undefined = undefined;
  try {
    const refAssignments = collection(firestore, `Instances/${instance}/Assignments`);
    const q = query(refAssignments, where("User", "==", uid));
    snap = await getDocs(q);
  } catch (e) {
    console.log("No access to assignments, claims may be unset yet. The app will refresh the token automatically.");

    saveAnalytics(instance, uid, "custom claims error");
    // estoy intentanto refrescar las claims si no las detecta
    const claim = httpsCallable(fbFunctions, "claim");
    await claim({ instance });
    await auth.getIdToken(true);

    // recargar todo cuando carguen las claims
    window.location.reload();
  }
  let assignments2: AssignmentV2[] = [];
  if (snap && !snap.empty) {
    assignments2 = snap.docs.map((d) => d.data() as AssignmentV2);
  }

  //Sacar las asignaciones de plan de estudio del usuario
  let snap2: QuerySnapshot<DocumentData> | undefined = undefined;
  try {
    const refAssignmentsSP = collection(firestore, `Instances/${instance}/StudyPlansAssignments`);
    const q = query(refAssignmentsSP, where("User", "==", uid));
    snap2 = await getDocs(q);
  } catch (e) {
    saveAnalytics(instance, uid, "custom claims error");
    console.log(
      "No access to assignments, claims may be unset yet. The app will refresh the token automatically."
    );
  }
  let assignmentsSP: StudyPlanAssignment[] = [];
  if (snap2 && !snap2.empty) {
    assignmentsSP = snap2.docs.map((d) => d.data() as StudyPlanAssignment);
  }

  //Consultar los cursos, para tener su info general a la mano
  let promises = assignments2.map((a: AssignmentV2) => {
    //Query a Firestore
    const refCourse = doc(
      firestore,
      `Instances/${instance}/Courses/${a.Course}`
    );
    return getDoc(refCourse);
  });

  const courseResults = await Promise.all(promises);
  const courses = courseResults.map((snap) => snap.data() as Course);
  let courseDict: Courses = {};

  courses.forEach((c) => {
    courseDict[c.ID!] = c;
  });

  //Consultar los planes de estudio, para tener su info general a la mano
  let promises2 = assignmentsSP.map((a: StudyPlanAssignment) => {
    //Query a Firestore
    const refStudyPlan = doc(firestore, `Instances/${instance}/StudyPlans/${a.StudyPlan}`);
    return getDoc(refStudyPlan);
  });

  const spResults = await Promise.all(promises2);
  const studyPlans = spResults.map((snap) => snap.data() as StudyPlan);
  let spDict: StudyPlans = {};

  studyPlans.forEach((c) => {
    spDict[c.ID!] = c;
  });

  const state: State = {
    assignments2: assignments2,
    studyPlansAssignments: assignmentsSP,
    studyPlans: spDict,
    courses: courseDict,
    modules: {},
    user: user,
  };

  return state;
};
