import { child, get, ref } from "firebase/database";
import { doc, getDoc } from "firebase/firestore";
import React, { Suspense, useEffect } from "react";
import { useAppContext } from "../../auth/appContext";
import { db, firestore } from "../../firebase";
import * as DashboardComponent from "./Instances";
import { armarHistograma, sortData } from "./utils";

const Dashboard: React.FC = () => {
  const { AppSettings } = useAppContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  switch (AppSettings.Name) {
    case "DHL":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <DashboardComponent.DHL />
        </Suspense>
      );
    default:
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <DashboardComponent.Default />
        </Suspense>
      );
  }
};

export const getCourseCount_Group = async (instanceName: string) => {
  // POR GRUPO
  let dataR = {};
  const metricGroupRef = doc(
    firestore,
    `Instances/${instanceName}/Metrics/CourseCount_Group`
  );
  await getDoc(metricGroupRef).then((snap) => {
    const d = snap.data() as any;
    let data: any[][] = sortData(d);

    let categories = Object.values(data).map((c) => c[0]);
    let series = [
      { name: "Grupos", data: Object.values(data).map((v) => v[1]) },
    ];
    dataR = { categories, series };
  });
  return dataR;
};

export const getCourseCount_Categories = async (instanceName: string) => {
  //   POR CATEGORIA
  let dataR = {};
  const metricCatRef = doc(
    firestore,
    `Instances/${instanceName}/Metrics/CourseCount_Categories`
  );
  await getDoc(metricCatRef).then((snap) => {
    const d = snap.data() as any;
    let data: any[][] = sortData(d);

    let categories = Object.values(data).map((c) => c[0]);
    let series = [
      { name: "Categorias", data: Object.values(data).map((v) => v[1]) },
    ];

    dataR = { categories, series };
  });

  return dataR;
};

export const getUsersCount = async (instanceName: string) => {
  //   USERS COUNT
  let data = {};
  const userCountRef = doc(
    firestore,
    `Instances/${instanceName}/Metrics/UsersCount`
  );
  await getDoc(userCountRef).then((snap) => {
    const d = snap.data() as any;
    data = armarHistograma(d, "Total de usuarios", true);
  });

  return data;
};

export const getCreatedUsersCount = async (instanceName: string) => {
  //   USERS CREATED
  let data = {};
  const usersCreateRef = doc(
    firestore,
    `Instances/${instanceName}/Metrics/CreatedUsersCount`
  );
  await getDoc(usersCreateRef).then((snap) => {
    let d = snap.data() as any;
    data = armarHistograma(d, "Usuarios creados");
  });
  return data;
};

/**
 * Leer el nodo Analytics de realtime database y armar los datos de las gráficas correspondientes
 */
export const leerRealtimeAnalytics = async (interfaceName: string) => {
  let userRef = `Analytics/${interfaceName}/`;
  let data = {};

  await get(child(ref(db), userRef)).then((snapshot) => {
    if (snapshot.exists()) {
      data = snapshot.val();
    }
  });

  return data;
};

export default Dashboard;
