import { IconMap } from "../interfaces";
import {
  Home,
  CalendarToday,
  MenuBookOutlined,
  LocalLibraryOutlined,
  LocalFireDepartmentOutlined,
  CampaignOutlined,
  LogoutOutlined,
  AssignmentTurnedInOutlined,
  MenuBook,
  HomeOutlined,
  CalendarTodayOutlined,
  AssignmentTurnedIn,
  LocalLibrary,
  LocalFireDepartment,
  Campaign,
  Logout,
  AnalyticsOutlined,
  Analytics,
  List,
  AccessTime,
  AccessTimeFilled,
  AdminPanelSettings,
  AdminPanelSettingsOutlined,
  GroupAdd,
  GroupAddOutlined,
  ManageAccounts,
  ManageAccountsOutlined,
  Check,
  CheckOutlined,
  AutoFixHighOutlined,
  AutoFixHigh,
} from "@mui/icons-material";

import * as IconsDHL from "./icons/DHL";

const icons: IconMap = {
  default: {
    activities: {
      filled: <AssignmentTurnedIn />,
      outlined: <AssignmentTurnedInOutlined />,
    },
    analytics: {
      filled: <Analytics />,
      outlined: <AnalyticsOutlined />,
    },
    announcements: {
      filled: <Campaign />,
      outlined: <CampaignOutlined />,
    },
    calendar: {
      filled: <CalendarToday />,
      outlined: <CalendarTodayOutlined />,
    },
    exercises: {
      filled: <LocalLibrary />,
      outlined: <LocalLibraryOutlined />,
    },
    home: {
      filled: <Home />,
      outlined: <HomeOutlined />,
    },
    list: {
      filled: <List />,
      outlined: <List />,
    },
    logout: {
      filled: <Logout />,
      outlined: <LogoutOutlined />,
    },
    quizzes: {
      filled: <LocalFireDepartment />,
      outlined: <LocalFireDepartmentOutlined />,
    },
    summary: {
      filled: <MenuBook />,
      outlined: <MenuBookOutlined />,
    },
    clock: {
      filled: <AccessTimeFilled />,
      outlined: <AccessTime />,
    },
    admin: {
      filled: <AutoFixHigh />,
      outlined: <AutoFixHighOutlined />,
    },
    global: {
      filled: <AdminPanelSettings />,
      outlined: <AdminPanelSettingsOutlined />,
    },
    enroller: {
      filled: <GroupAdd />,
      outlined: <GroupAddOutlined />,
    },
    studyplans: {
      filled: <MenuBook />,
      outlined: <MenuBookOutlined />,
    },
    editprofiles: {
      filled: <ManageAccounts />,
      outlined: <ManageAccountsOutlined />,
    },
    check: {
      filled: <Check />,
      outlined: <CheckOutlined />,
    },
  },
  DHL: {
    activities: {
      filled: <IconsDHL.activities />,
      outlined: <IconsDHL.activities />,
    },
    announcements: {
      filled: <IconsDHL.announcements />,
      outlined: <IconsDHL.announcements />,
    },
    calendar: {
      filled: <IconsDHL.calendar />,
      outlined: <IconsDHL.calendar />,
    },
    exercises: {
      filled: <IconsDHL.exercises />,
      outlined: <IconsDHL.exercises />,
    },
    home: {
      filled: <IconsDHL.home />,
      outlined: <IconsDHL.home />,
    },
    summary: {
      filled: <IconsDHL.summary />,
      outlined: <IconsDHL.summary />,
    },
    reta: {
      filled: <IconsDHL.reta />,
      outlined: <IconsDHL.reta />,
    },
    catalog: {
      filled: <IconsDHL.catalog />,
      outlined: <IconsDHL.catalog />,
    },
    areuready: {
      filled: <IconsDHL.areuready />,
      outlined: <IconsDHL.areuready />,
    },
    check: {
      filled: <IconsDHL.check />,
      outlined: <IconsDHL.check />,
    },
    close: {
      filled: <IconsDHL.close />,
      outlined: <IconsDHL.close />,
    },
    selectPartic: {
      filled: <IconsDHL.selectPartic />,
      outlined: <IconsDHL.selectPartic />,
    },
    alert: {
      filled: <IconsDHL.alert />,
      outlined: <IconsDHL.alert />,
    },
    admin: {
      filled: <IconsDHL.admin />,
      outlined: <IconsDHL.admin />,
    },
    
  },
};

/**
 * Obtener iconos en base a instancia
 * @param instance nombre de instancia
 * @param icon icono
 * @param variant variante
 * @returns icono en base a instancia
 */
export const getIcon = (instance: string, icon: string, variant: "filled" | "outlined") => {
  if (instance !== "default") {
    if (icons[instance][icon]) {
      if (icons[instance][icon][variant]) {
        return icons[instance][icon][variant];
      }
    }
  } else {
    return icons[instance][icon][variant];
  }

  //Regresar el default
  if (icons.default[icon][variant] !== undefined) {
    return icons.default[icon][variant];
  } else {
    return icons.default["home"]["filled"];
  }
};
