export class instructorClass {
    readonly Name: string;
    readonly Region: string;
    readonly Email: string;
    readonly Telefono: number;
    readonly Priority: number;
    readonly ID: string;

    constructor(priority: number) {
        this.Email = ''
        this.Name = ''
        this.Region = ''
        this.Telefono = 0
        this.Priority = priority
        this.ID = ''
    }
}