import withAuth from "../../../../auth/withAuth";
import AnnouncementsView from "./view";
import { useDataContext } from "../../../../auth/dataContext";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../../auth/appContext";
import { StudyTip } from "../../../../interfaces/course";
import { Timestamp, collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../../../../firebase";
import { Contact } from "../../../../interfaces";
import { orderBy } from "lodash";

const Announcements: React.FC = () => {
	const { state } = useDataContext();
	const appContext = useAppContext();
	const [instructors, setInstructors] = useState<Contact[]>([]);
	const [studyTips, setStudyTips] = useState<StudyTip[]>([]);

	useEffect(() => {
		if (state && state.studyTips) {
			// Si el usuario es tester mostrar todos los study tips ordenados
			if (state.user.Data.isTester) {
				setStudyTips(orderBy(state.studyTips, 'From', 'asc'))
			} else
				// Si no es tester solo mostrar solo study tips con fecha anterior a la actual
				setStudyTips(
					// Ordenar por fecha de manera ascendente
					orderBy(studyTips.filter(
						({ From }) =>
							From && (From as Timestamp).toDate() <= new Date()),
						'From',
						'asc'
					)
				)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	useEffect(() => {
		const refInstructors = collection(
			firestore,
			`Instances/${appContext.AppSettings.Name}/Instructors`
		);
		const instructorsListener = onSnapshot(refInstructors, snap => {
			if (!snap.empty) {
				const docs = snap.docs;
				const instructorsList = docs.map((d: any) => d.data() as Contact);
				setInstructors(instructorsList.sort((a, b) => a.Priority - b.Priority));
			} else {
				setInstructors([])
			}
		})
		return () => {
			instructorsListener()
		}
	}, [appContext.AppSettings.Name]);

	return <AnnouncementsView
		studyTips={studyTips}
		instructors={instructors}
	/>
};

export default withAuth(Announcements);
