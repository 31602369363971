import { Routes, Route, Navigate } from "react-router-dom";

// Components
import HomePage from "../../../../pages/Home";
import CatalogPage from "../../../../pages/Catalog";
import CoursePage from "../../../../pages/Course";
import StudyPage from "../../../../pages/Study";
import AdminPage from "../../../../pages/Admin";
import GlobalPage from "../../../../pages/Global";
import ReportsPage from "../../../../pages/Reports/index";
import Announcements from "../../../../pages/Announcements";
import EnrollerPage from "../../../../pages/Enroller";
import { useDataContext } from "../../../../auth/dataContext";
import BackdropComponent from "../../../Backdrop";
import StudyPlans from "../../../../pages/StudyPlans";
import StudyPlansCatalog from "../../../../pages/StudyPlansCatalog";
import EditProfiles from "../../../../pages/EditProfiles";

const DefaultRouter = () => {
  const { state } = useDataContext();

  const getRoutesForRole = () => {
    const routes: any[] = [
      <Route key="*" path="*" element={<Navigate to="/Home" />} />,
    ];
    if (state.user.Access!.some((r) => ["student"].includes(r))) {
      routes.push([
        <Route key="/Home" path="/Home" element={<HomePage />} />,
        <Route key="/Catalog" path="/Catalog" element={<CatalogPage />} />,
        <Route
          key="/Course"
          path="/Course/:courseID"
          element={<CoursePage />}
        />,
        <Route key="/Study" path="/Study/:courseID" element={<StudyPage />} />,
        <Route
          key="/Announcements"
          path="/Announcements"
          element={<Announcements />}
        />,
        <Route
          key="/StudyPlans/:id"
          path="/StudyPlans/:id"
          element={<StudyPlans />}
        />,
        <Route
          key="/StudyPlans"
          path="/StudyPlans"
          element={<StudyPlansCatalog />}
        />,
      ]);
    }
    if (state.user.Access?.some((r) => ["reporter"].includes(r))) {
      routes.push([
        <Route key="/Reports" path="/Reports" element={<ReportsPage />} />,
      ]);
    }
    if (state.user.Access?.some((r) => ["admin"].includes(r))) {
      routes.push([
        <Route key="/Admin" path="/Admin" element={<AdminPage />} />,
        <Route
          key="/EditProfiles"
          path="/EditProfiles"
          element={<EditProfiles />}
        />,
      ]);
    }
    if (state.user.Access?.some((r) => ["global"].includes(r))) {
      routes.push([
        <Route key="Global" path="/Global" element={<GlobalPage />} />,
      ]);
    }
    if (state.user.Access?.some((e) => ["enroller"].includes(e))) {
      routes.push([
        <Route key="/Enroller" path="/Enroller" element={<EnrollerPage />} />,
      ]);
    }
    return routes;
  };

  if (state.user.Access) {
    return (
      <>
        <Routes>{getRoutesForRole()}</Routes>
      </>
    );
  } else {
    return <BackdropComponent />;
  }
};

export default DefaultRouter;
