import { makeStyles,  } from '@mui/styles';

const useStyles = makeStyles({
    centerDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
})

export default useStyles;