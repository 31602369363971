import { CheckBox, Close, Edit, RemoveCircle } from "@mui/icons-material";
import { Autocomplete, Chip, Grid, Grow, IconButton, TextField, Typography, Zoom } from "@mui/material";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useAppContext } from "../../../../auth/appContext";
import { firestore } from "../../../../firebase";
import UsersAdminView from "./usersTableView";
import { theme } from "../../../SelectParticipants/Instances/DHL/Components/CustomTable/style";
import { TableFilters } from "../../../SelectParticipants/interfaces";
import _ from "lodash";

const defParams: GridColDef = {
    field: "",
    headerAlign: "center",
    align: "center",
    hideSortIcons: true,
    minWidth: 130,
    sortable: false,
    disableColumnMenu: true,
};

const UsersAdmin: React.FC = () => {
    const [selectedUser, setSelectedUser] = useState<any>();
    //#region Datagrid
    const [rawData, setRawData] = useState<any[]>([]);
    const [filters, setFilters] = useState<TableFilters>({});
    const [filteredData, setFilteredData] = useState([]);
    let cols: GridColDef[] = [
        { ...defParams, field: 'Name', headerName: 'Nombre', width: 280, renderCell: (params) => params.row.Name },
        { ...defParams, field: 'Email', headerName: 'Correo', width: 280, renderCell: (params) => params.row.Email },
        { ...defParams, field: 'SVC', headerName: 'SVC', renderCell: (params) => params.row.Data.SVC },
        { ...defParams, field: 'Region', headerName: 'Region', renderCell: (params) => params.row.Data.Region },
        {
            ...defParams, field: 'Administrador', headerName: 'Admin', align: 'center', headerAlign: 'center', renderCell: (params) =>
                params.row.Administrador === 'Si' ? <CheckBox /> : <Close />
        },
        {
            ...defParams, field: 'Estudiante', headerName: 'Estudiante', align: 'center', headerAlign: 'center', renderCell: (params) =>
                params.row.Estudiante === 'Si' ? <CheckBox /> : <Close />
        },
        {
            ...defParams, field: 'Reportero', headerName: 'Lect. Reportes', align: 'center', headerAlign: 'center', renderCell: (params) =>
                params.row.Reportero === 'Si' ? <CheckBox /> : <Close />
        },
        {
            ...defParams, field: 'Admin_Contenido', headerName: 'Admin. Contenido', align: 'center', headerAlign: 'center', renderCell: (params) =>
                params.row.Admin_Contenido === 'Si' ? <CheckBox /> : <Close />
        },
        {
            ...defParams, field: 'edit', headerName: '', align: 'center', renderCell: (params) => (
                <IconButton onClick={() => {
                    // console.log(params.row);
                    setSelectedUser(params.row);
                }}>
                    <Edit />
                </IconButton>)
        },
    ]

    cols = cols.map((col) => {
        return {
            ...col,
            renderHeader: (headerParams: GridColumnHeaderParams) => {
                const headersNoFilter = ['edit'];
                if (!headersNoFilter.includes(headerParams.field!)) {
                    return (
                        <Grid container paddingTop={3}>
                            <Grid item xs={12}>
                                <Typography sx={{
                                    fontSize: theme.typography.pxToRem(13),
                                    fontWeight: "bold",
                                    color: theme.palette.grey[600]
                                }}>
                                    {headerParams.colDef.headerName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    value={filters[col.field] ?? []}
                                    fullWidth
                                    multiple
                                    options={_.uniqBy(rawData.map((d: any) => d[headerParams.field]),
                                        (dat) => dat)
                                    }
                                    size='small'
                                    autoComplete={false}
                                    autoHighlight={false}
                                    disableListWrap
                                    disableClearable
                                    disableCloseOnSelect
                                    renderTags={() => undefined}
                                    getOptionLabel={(option: any) => option.toString()}
                                    style={{ width: "100%" }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={`Selecciona ${headerParams.colDef.headerName}...`}
                                            variant='outlined'
                                            size='small'
                                            inputProps={{
                                                ...params.inputProps,
                                                style: {
                                                    ...params.inputProps.style,
                                                    height: 3,
                                                    padding: "8px 4px",
                                                },
                                            }}
                                        />
                                    )}
                                    onChange={(e, val) => {
                                        let newfilters = { ...filters };
                                        if (val && val.length > 0) {
                                            if (!newfilters[col.field]) {
                                                newfilters[col.field] = [];
                                                newfilters[col.field].push(...val);
                                            } else {
                                                newfilters[col.field] = val;
                                            }
                                        } else {
                                            delete newfilters[col.field];
                                        }
                                        setFilters(newfilters);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    );
                } else {
                    return <Typography sx={{
                        fontSize: theme.typography.pxToRem(13),
                        fontWeight: "bold",
                        color: theme.palette.grey[600]
                    }}>
                        {headerParams.colDef.headerName}
                    </Typography>
                }
            },
        };
    });

    const filterTable = useMemo(() =>
        (rows: any) => {
            var newData = rows;
            for (let f of Object.keys(filters)) {
                newData = newData.filter((x: any) => filters[f].includes(x[f]));
            }
            setFilteredData(newData);
        }, [filters]);

    useEffect(() => {
        const rows = rawData.map((data: any) => {
            return {
                Name: data.Name,
                Email: data.Email,
                UID: data.UID,
                Provider: data.Provider,
                Administrador: data.Administrador,
                Estudiante: data.Estudiante,
                Admin_Contenido: data.Admin_Contenido,
                Reportero: data.Reportero,
                Access: data.Access,
                Data: data.Data,
            };
        });
        filterTable(rows);
    }, [filters, rawData, filterTable]);

    const buttonRemoveAllFiltersByColumn = (f: string) => {
        // Extraigo el nombre de la columna
        return (
            <IconButton
                title={`Remover todos los filtros de ${f}`}
                color='secondary'
                onClick={() => {
                    // Creo una copia del objeto filters
                    let newfilters = { ...filters };
                    // Borro todos los filtros de la columna en cuestión
                    delete newfilters[f];
                    // Actualizo el objeto filters con la nueva copia
                    setFilters(newfilters);
                }}>
                <RemoveCircle />
            </IconButton>
        );
    };

    const StringifyFilters = () => {
        let keys = Object.keys(filters);
        if (keys.length > 0) {
            var items = keys.map((f) => {
                let chips = filters[f].map((item, index) => {
                    return (
                        <Grow in={true} key={index}>
                            <Chip
                                key={item}
                                sx={{ marginRight: 0.5 }}
                                color='primary'
                                onClick={() => {
                                    let newFilters = { ...filters };
                                    // En el caso de que nomás quede un elemento, se elimina la columna completa
                                    if (newFilters[f].length === 1) {
                                        delete newFilters[f];
                                        return setFilters(newFilters);
                                    }
                                    // Se elimina el elemento seleccionado mediante el índice
                                    newFilters[f].splice(index, 1);

                                    // Se actualiza el objeto filters
                                    setFilters(newFilters);
                                }}
                                title={"Remove filter"}
                                label={item}
                            />
                        </Grow>
                    );
                });
                return (
                    <Zoom in={true} key={f}>
                        <Grid style={{ marginBottom: "4px" }}>
                            {buttonRemoveAllFiltersByColumn(f)} {f} {chips}
                        </Grid>
                    </Zoom>
                );
            });
            return [
                <Typography fontWeight='bold' variant='body1' gutterBottom key='title'>
                    Filtros aplicados:
                </Typography>,
                ...items,
            ];
        }
        return [];
    };

    const stringifyFilters = StringifyFilters();
    //#endregion
    const { AppSettings } = useAppContext();

    useEffect(() => {
        const usersRef = collection(firestore, `Instances/${AppSettings.Name}/Users`);
        const observer = onSnapshot(usersRef, (res) => {
            if (!res.empty) {
                const users = res.docs.map((user) => {
                    const data = user.data();
                    return {
                        Name: data.Name,
                        Email: data.Email,
                        Provider: data.Provider,
                        UID: data.UID,
                        Data: data.Data,
                        Administrador: data.Access.includes('admin') ? 'Si' : 'No',
                        Admin_Contenido: data.Access.includes('content_manager') ? 'Si' : 'No',
                        Reportero: data.Access.includes('reporter') ? 'Si' : 'No',
                        Estudiante: data.Access.includes('student') ? 'Si' : 'No',
                        Access: data.Access,
                    };
                });
                setRawData(users);
            }
        });

        return () => {
            observer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <UsersAdminView cols={cols} rows={filteredData} stringifyFilters={stringifyFilters}
        user={selectedUser} setUser={setSelectedUser} />
}
export default UsersAdmin;