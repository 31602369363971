import { InfoOutlined } from "@mui/icons-material";
import {
    Grid,
    Tooltip,
    Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import RenderExpandableCell from "../../../../../../components/ExcelValidation/ExpandableCell";
import { validationWorkbook } from "../../../../../../interfaces/validation";

interface Props {
    results: validationWorkbook;
}

const SheetValidation: React.FC<Props> = ({ results }) => {
    // columnas de la tabla de errores encontrados
    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "Celda",
            flex: 0.5,
            renderCell: (params: GridRenderCellParams) => (
                <RenderExpandableCell {...params} />
            ),
        },
        {
            field: "header",
            headerName: "Campo",
            flex: 0.5,
            renderCell: (params: GridRenderCellParams) => (
                <RenderExpandableCell {...params} />
            ),
        },

        {
            field: "value",
            headerName: "Valor",
            flex: 0.5,
            renderCell: (params: GridRenderCellParams) => (
                <RenderExpandableCell {...params} />
            ),
        },
        {
            field: "details",
            headerName: "Detalles",
            flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <RenderExpandableCell {...params} />
            ),
        },
        {
            field: "rules",
            headerName: "Reglas",
            renderCell: (params) => (
                <Tooltip title={params.row.rules} placement="top">
                    <InfoOutlined fontSize="medium" />
                </Tooltip>
            ),
        },
    ];

    const [rows, setRows] = useState<any>({}); //filas de la tabla por hoja del excel

    useEffect(() => {
        // por cada hoja del excel
        let tempRows: any = {};
        Object.keys(results).forEach((key) => {
            let tempHoja: any[] = [];
            // armar las filas por cada error encontrado
            Object.keys(results[key]).forEach((celda) => {
                let tempRow = {
                    id: celda,
                    header: results[key][celda].header,
                    value: results[key][celda].value,
                    details: results[key][celda].details,
                    rules: results[key][celda].rules,
                };

                tempHoja.push(tempRow);
            });
            // agregar arreglo de filas con la llave de la hoja
            tempRows[key] = tempHoja;
        });

        setRows(tempRows);
    }, [results]);

    return (
        <Grid container>
            {Object.keys(results).map((key) => (
                <Grid item xs={12} key={key}>
                    <DataGrid
                        density="compact"
                        rows={rows[key]}
                        columns={columns}
                        rowsPerPageOptions={[10, 20, 50, 100, 500, 1000]}
                        autoHeight={true}
                        disableSelectionOnClick
                        pagination
                        componentsProps={{
                            pagination: {
                                labelRowsPerPage: "Filas por página",
                            },
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    );

};

export default SheetValidation;