import { useContext } from "react";
import { AccountCircleOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { UserContext } from "../../../../../auth/userContext";
import { toTitleCase } from "../../../../../helpers/index";
import useStyles from "./style";

const UserProfileButton_DHL = () => {
	const classes = useStyles();
	const { user } = useContext(UserContext);

	let role = '';
	if (user.Data.isCourier) role = "Courier";
	if (user.Data.isLeader) role = "Leader";
	if (user.Data.isManager) role = "Manager";
	if (user.Data.isAdmin) role = "Admin";
	if (user.Data.isTester) role = "Tester";
	if (user.Data.isYIS) role = "YIS";


	return (
		<div className={classes.mainContainer}>
			<AccountCircleOutlined className={classes.icon} />
			<div className={classes.nameContainer}>
				<Typography
					sx={{fontWeight: 'bold'}}
				>
					{toTitleCase(user.Name!)}
				</Typography>
				<Typography
					color='white'
				>
					{role}
				</Typography>
			</div>
		</div>
	);
};

export default UserProfileButton_DHL;
