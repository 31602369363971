import { useEffect, useState } from "react";
import { useDataContext } from "../../../../auth/dataContext";
import { MaterialItem } from "../../../../interfaces/course";
import AreYouReadyView from "./view";
import _ from "lodash";

const AreYouReady = () => {
  const { state } = useDataContext();
  const assignment = state.assignments2![0];
  let testCount = 0;
  if (assignment) {
    const currentCourseId = assignment.Course;
    const testCount = _.sumBy(state.modules[currentCourseId], (module) =>
      _.sumBy(module.Topics, (topic) =>
        _.sumBy(topic.Material, (material) =>
          material.Type === "test" ? 1 : 0
        )
      )
    );
    const randQuestions: number[] = [];
    const questionsPerTest = Math.floor(50 / testCount);
    const tCountArray = Array.from(Array(testCount).keys());
    const rcount = 50 % testCount;
    let tcount = 0;
    for (let index = 0; index < rcount; index++) {
      const randomIndex = Math.floor(Math.random() * tCountArray.length);
      randQuestions.push(tCountArray[randomIndex]);
      tCountArray.splice(randomIndex, 1);
    }

    const [mixTest, setMixtTest] = useState<MaterialItem>();
    const [isReset, setIsReset] = useState<boolean>();

    useEffect(() => {
      window.scrollTo(0, 0);
      let testsTemp: MaterialItem = {
        Name: "Mix Test",
        Type: "test",
        Questions: [],
      };

      let lastCompletedModuleIndex = -1;

      // Obtener el último módulo completado
      assignment!.Progress.forEach((moduleProgress, index) => {
        if (moduleProgress.ModuleProgress >= 1) {
          lastCompletedModuleIndex = index;
        }
      });

      // lastCompletedModuleIndex = 7;

      if (
        lastCompletedModuleIndex ===
        state.modules[currentCourseId].length - 1
      ) {
        //obtener las preguntas de cada examen
        state.modules[currentCourseId].forEach((module) => {
          // recorrer sus temas
          module.Topics.forEach((topic) => {
            // recorrer el material del tema
            topic.Material.forEach((material) => {
              // si el material es tipo "test"
              if (material.Type === "test") {
                // obtener las preguntas aleatorioas por examen //
                let questionNum = randQuestions.includes(tcount)
                  ? questionsPerTest + 1
                  : questionsPerTest;
                tcount++;


                // si el número de preguntas a tomar aleatorias por examen es menor que el total de preguntas de ese examen
                if (questionNum < material.Questions!.length) {
                  let questions: number[] = []; //arreglo para almacenar los indices aleatorios

                  // generar los indices aleatorios de las preguntas del examen
                  do {
                    let random = Math.floor(
                      Math.random() * material.Questions!.length
                    );
                    if (!questions.includes(random)) {
                      questions.push(random);
                    }
                  } while (questions.length < questionNum);

                  // recorrer los indices aleatorios e ir llenando el nuevo examen
                  // marcar de qué modulo y tema son las preguntas
                  questions.forEach((i) => {
                    testsTemp = {
                      ...testsTemp,
                      Questions: [
                        ...testsTemp.Questions!,
                        {
                          ...material.Questions![i],
                          Module: module.Name,
                          Tema: topic.Name,
                        },
                      ],
                    };
                  });
                } else {
                  // si el número de preguntas a tomar aleatorias por examen es mayor que el total de preguntas del examen
                  // agregar todas las preguntas al nuevo examen
                  testsTemp = {
                    ...testsTemp,
                    Questions: [
                      ...testsTemp.Questions!,
                      ...material.Questions!,
                    ],
                  };
                }
              }
            });
          });
        });
      }

      setMixtTest(testsTemp);
    }, [isReset]);

    //   ¿Volver a hacer el mix?
    const reset = () => {
      setIsReset(!isReset);
    };

    return (
      <AreYouReadyView assignment={assignment} mixTest={mixTest} back={reset} />
    );
  } else {
    return (
      <h2 className="fadeInRigth">
        Debes estar inscrito al curso activo para poder ver esta sección.
      </h2>
    );
  }
};

export default AreYouReady;
