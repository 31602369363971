import { User } from 'firebase/auth';
import { User as VU_User } from "../../interfaces";
import * as DHL from './DHL';
import * as Default from './Default';
import { State } from '../../interfaces/context';


/**
 * Obtener información requerida por el contexto de react
 * @param instance Instancia en la que estamos
 * @param user Usuario que inició sesión
 * @param state State actual que puede o no traer ya algo de información
 * @param domains ???
 * @returns Información para el contexto de react
 */
export const AuthHandler = (instance: string, user: User, state: State, domains: string[]):
    Promise<{ user: VU_User, state: State } | undefined> => {
    switch (instance) {
        case 'DHL':
            return DHL.GetDHLUser(user, state);
        default:
            return Default.GetUser_Default(instance, user, state, domains);
    }
}