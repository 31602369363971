import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";
import { DrawerItem } from "../../../../interfaces/drawer";
import { getIcon } from "../../../../helpers/iconHelper";
import { fbAuth } from "../../../../firebase";
import { signOut } from "@firebase/auth";
import { useDataContext } from "../../../../auth/dataContext";
import useStyles from "./style";

const instanceName = "default";

const botomNavItems: any[] = [
  {
    title: "Inicio",
    path: "/Home",
    availableFor: ["student"],
    key: 1,
    icon: getIcon(instanceName, "home", "outlined"),
  },
  {
    title: "Catálogo",
    path: "/Catalog",
    availableFor: ["student"],
    key: 2,
    icon: getIcon(instanceName, "list", "outlined"),
  },
  {
    title: "Planes de estudio",
    path: "/StudyPlans",
    availableFor: ["student"],
    key: 9,
    icon: getIcon(instanceName, "studyplans", "outlined"),
  },
  {
    title: "Cerrar sesión",
    path: "/Logout",
    availableFor: ["all"],
    key: 15,
    icon: getIcon(instanceName, "logout", "outlined"),
  },
];

const allItems: any[] = [
  {
    title: "Inicio",
    path: "/Home",
    availableFor: ["student"],
    key: 1,
    icon: getIcon(instanceName, "home", "outlined"),
  },
  {
    title: "Catálogo",
    path: "/Catalog",
    availableFor: ["student"],
    key: 2,
    icon: getIcon(instanceName, "list", "outlined"),
  },
  {
    title: "Calendario",
    path: "/Calendar",
    // availableFor: ['student'],
    availableFor: [],
    key: 3,
    icon: getIcon(instanceName, "calendar", "outlined"),
  },
  {
    title: "Curso",
    path: "/Course",
    // availableFor: ['student'],
    availableFor: [],
    key: 4,
    icon: getIcon(instanceName, "summary", "outlined"),
  },
  {
    title: "Anuncios",
    path: "/Announcements",
    availableFor: [],
    key: 5,
    icon: getIcon(instanceName, "announcements", "outlined"),
  },
  {
    title: "Reportes",
    path: "/Reports",
    availableFor: ["reporter"],
    key: 6,
    icon: getIcon(instanceName, "analytics", "outlined"),
  },
  {
    title: "Inscribir estudiantes",
    path: "/Enroller",
    availableFor: ["enroller"],
    key: 9,
    icon: getIcon(instanceName, "enroller", "outlined"),
  },
  {
    title: "Planes de estudio",
    path: "/StudyPlans",
    availableFor: ["student"],
    key: 9,
    icon: getIcon(instanceName, "studyplans", "outlined"),
  },
  {
    title: "Editar Perfiles",
    path: "/EditProfiles",
    availableFor: ["admin"],
    key: 9,
    icon: getIcon(instanceName, "editprofiles", "outlined"),
  },
  {
    title: "Administrador",
    path: "/Admin",
    availableFor: ["admin"],
    key: 7,
    icon: getIcon(instanceName, "admin", "outlined"),
  },
  {
    title: "Global",
    path: "/Global",
    availableFor: ["global"],
    key: 9,
    icon: getIcon(instanceName, "global", "outlined"),
  },
  {
    title: "Cerrar sesión",
    path: "/Logout",
    availableFor: ["all"],
    key: 15,
    icon: getIcon(instanceName, "logout", "outlined"),
  },
];

export const AppbarSX = {
  background: "linear-gradient(90deg,#fc0 0,#fc0 48%,#ffe57f 70%,#fff0b2)",
};

export const GetPageTitle = (selected: string) => {
  return allItems.find((item) => item.path === selected)?.title;
};

interface Props {
  selected: string;
  setOpen: (open: boolean) => void;
}

//#region DRAWER ITEMS - NAVIGATION
export const GetDrawerItems: React.FC<Props> = ({ selected, setOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useDataContext();
  const [drawerItems, setDrawerItems] = useState<DrawerItem[]>([]);

  useEffect(() => {
    const userRoles = state.user.Access ?? [];
    const _items = allItems
      .filter(
        (x) =>
          x.availableFor.includes("all") ||
          (x.availableFor as Array<string>).some((r) => userRoles.includes(r))
      )
      .map((y) => {
        const item: DrawerItem = {
          ...y,
          hidden: false,
        };

        return item;
      });
    setDrawerItems(_items);
  }, [state]);

  const logout = () => {
    signOut(fbAuth);
  };

  const handleSelectRoute = (path: string) => {
    navigate(path);
  };

  if (drawerItems.length === 0) {
    return <></>;
  } else {
    return (
      <List
        sx={{
          paddingLeft: "12px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          {drawerItems.map((item, index) => {
            if (item.path !== "/Logout")
              return (
                <Tooltip title={item.title} key={index}>
                  <ListItem
                    className={
                      item.path === selected
                        ? classes.listItemSelected
                        : classes.listItem
                    }
                    button
                    key={item.key}
                    // disabled={state.user.Locked}
                    onClick={() => {
                      handleSelectRoute(item.path);
                      setOpen(false);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: theme.palette.secondary.main,
                        transform: "scale(1.1)",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItem>
                </Tooltip>
              );
            else return null;
          })}
        </div>
        {/* LOGOUT ITEM - hasta abajo y blanco */}
        <ListItem
          style={{
            marginBottom: 16,
          }}
          className={classes.listItem}
          button
          onClick={logout}
        >
          <ListItemIcon
            sx={{
              color: "white",
            }}
          >
            {drawerItems[drawerItems.length - 1].icon}
          </ListItemIcon>
          <ListItemText primary={drawerItems[drawerItems.length - 1].title} />
        </ListItem>
      </List>
    );
  }
};

//#endregion DRAWER ITEMS - NAVIGATION

//Bottom Navigation
export const GetBottomNavigationItems: React.FC<Props> = ({
  selected,
  setOpen,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useDataContext();
  const [navItems, setNavItems] = useState<DrawerItem[]>([]);
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    const userRoles = state.user.Access ?? [];
    const _items = botomNavItems
      .filter(
        (x) =>
          x.availableFor.includes("all") ||
          (x.availableFor as Array<string>).some((r) => userRoles.includes(r))
      )
      .map((y) => {
        const item: DrawerItem = {
          ...y,
          hidden: false,
        };

        return item;
      });
    setNavItems(_items);
  }, [state]);

  const logout = () => {
    signOut(fbAuth);
  };

  const handleSelectRoute = (path: string) => {
    navigate(path);
  };

  if (navItems.length === 0) {
    return <></>;
  } else {
    return (
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation showLabels>
          {navItems.map((item, index) => {
            if (item.path !== "/Logout")
              return (
                <BottomNavigationAction
                  label={item.title}
                  key={item.key}
                  icon={
                    item.icon
                  }
                  onClick={() => {
                    handleSelectRoute(item.path);
                  }}
                />
              );
            else return null;
          })}

          {/* LOGOUT ITEM - hasta abajo y blanco */}
          {/* <ListItem
          style={{
            marginBottom: 16,
          }}
          className={classes.listItem}
          button
          onClick={logout}
        >
          <ListItemIcon
            sx={{
              color: "white",
            }}
          >
            {navItems[navItems.length - 1].icon}
          </ListItemIcon>
          <ListItemText primary={navItems[navItems.length - 1].title} />
        </ListItem> */}
        </BottomNavigation>
      </Paper>
    );
  }
};
