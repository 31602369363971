import { Grid, IconButton, LinearProgress, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { orderBy } from 'lodash';
import React, { useState, useEffect } from 'react'
import { useAppContext } from '../../../../../auth/appContext';
import { firestore, storage } from '../../../../../firebase';
import { Certification } from '../../../../../interfaces/certifications';
import CertificationsTableView from './view';
import { ref } from 'firebase/storage';
import { getDownloadUrl } from '../../../../../helpers';
import { GridColDef } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import { theme } from '../../../../SelectParticipants/Instances/DHL/Components/CustomTable/style';
import DetailsCertif from './detailsApplicator';
import Fuse from 'fuse.js';
import { useDataContext } from '../../../../../auth/dataContext';
import { useShowMessage } from '../../../../../providers/MessageProvider/MessageProvider';

interface Props { showButton?: boolean; }

const defProps: GridColDef = {
    field: '',
    width: 200,
    disableColumnMenu: true,
    hideSortIcons: true,
    sortable: false,
    headerAlign: 'center',
}

const CertificationsTable: React.FC<Props> = ({ showButton }) => {
    const { AppSettings } = useAppContext();
    const { state } = useDataContext();
    //#region controlTabla
    const [certDates, setCertDates] = useState<Certification[]>([]);
    const [searchResults, setSearchResults] = useState<Certification[]>();
    const [searchQuery, setSearchQuery] = useState<string>('');
    //#endregion 
    const [certification, setCertification] = useState<Certification>();
    const [canSave, setCanSave] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<Certification>();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const columnas: GridColDef[] = [
        {
            ...defProps,
            field: 'SVC',
            headerName: 'SVC',
            align: 'center',
            width: 100,
            renderCell: (params: any) => params.row.SVC
        },
        {
            ...defProps,
            field: 'Region',
            align: 'center',
            width: 100,
            renderCell: (params: any) => params.row.Region
        },
        {
            ...defProps,
            field: 'Date',
            headerName: 'Fecha',
            align: 'center',
            renderCell: (params: any) => `${capitalLetter(params.row.DateTime.toDate().toLocaleDateString('es-MX', { weekday: 'long' }))} 
            ${params.row.DateTime.toDate().toLocaleDateString('es-MX', { day: 'numeric' })} de 
            ${capitalLetter(params.row.DateTime.toDate().toLocaleDateString('es-MX', { month: 'long' }))}
            ${params.row.DateTime.toDate().toLocaleDateString('es-MX', { year: 'numeric' })}`,
        },
        {
            ...defProps,
            width: 100,
            field: 'Time',
            headerName: 'Hora',
            align: 'center',
            renderCell: (params: any) => params.row.DateTime.toDate().toTimeString().slice(0, 5),
        },
        {
            ...defProps,
            field: 'Place',
            headerName: 'Lugar',
            width: 300,
            renderCell: (params: any) => params.row.Place
        },
        {
            ...defProps,
            field: 'Leader',
            headerName: 'Lider',
            align: 'center',
            renderCell: (params: any) => params.row.Leader
        },
        {
            ...defProps,
            field: 'Applicators',
            headerName: 'Aplicadores',
            align: 'center',
            renderCell: (params: any) => {
                const aplicadores = params.row.Applicators.filter((d: string) => d.trim() !== '');
                if (aplicadores && aplicadores.length > 0) {
                    if (isMobile)
                        return (
                            <DetailsCertif
                                Applicators={params.row.Applicators}
                            />
                        )
                    else
                        return (
                            <Grid container justifyContent='center'>
                                <Tooltip
                                    arrow
                                    title={params.row.Applicators.map(
                                        (aplicador: string, key: number) => (
                                            <Typography
                                                key={key}
                                                align='center'
                                                variant='caption'
                                            >
                                                {aplicador}
                                            </Typography>
                                        ))}
                                >
                                    <Grid item xs={12} container justifyContent='center'>
                                        {`${params.row.Applicators.length > 1 ?
                                            `${params.row.Applicators.length} aplicadores` : `${params.row.Applicators} `}`}
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        )
                }
            },
        },
    ]
    const { showMessage } = useShowMessage();

    /**
     * Generar columna extra si hay roles globales de admin o tester
     * @returns columnas visibles de acuerdo a rol
     */
    const generarColumnas = (): GridColDef[] => {
        if (showButton && (state.user.Data.isAdmin || state.user.Data.isTester)) {
            const columnasIniciales = [...columnas];
            columnasIniciales.push({
                ...defProps,
                field: 'options',
                headerName: '',
                align: 'center',
                width: 150,
                renderCell: (params: any) =>
                    <Grid container spacing={2} justifyContent='center'>
                        <Grid item container justifyContent='center' xs={3}>
                            <IconButton
                                onClick={() => setCertification(params.row)}
                            >
                                <Edit />
                            </IconButton>
                        </Grid>
                        <Grid item container justifyContent='center' xs={3}>
                            <IconButton
                                color='error'
                                onClick={() => setOpenDelete(params.row)}
                            >
                                <Delete />
                            </IconButton>
                        </Grid>
                    </Grid>
            })
            return columnasIniciales;
        }
        else return columnas;
    }

    /**
     * No guardar si hay campos vacíos
     */
    useEffect(() => {
        if (certification &&
            (certification.Leader.trim() === "" ||
                certification.Place.trim() === "" ||
                certification.Region.trim() === "" ||
                certification.SVC.trim() === "")) {
            setCanSave(false)
        } else {
            setCanSave(true)
        }
    }, [certification]);

    /**
     * Buscador de fuse para tabla certificaciones
     */
    useEffect(() => {
        if (searchQuery.trim() !== '') {
            const resultados = new Fuse(certDates, {
                keys: ['SVC'],
                isCaseSensitive: false,
                threshold: 0.4,
                minMatchCharLength: 3,
            }).search(searchQuery).map(
                (res) => { return res.item });
            setSearchResults(resultados);
        } else
            setSearchResults(undefined);
    }, [searchQuery, certDates])


    /**
     * Convierte la primera letra de un texto en mayuscula
     * @param string texto
     * @returns texto con primera letra en mayuscula
     */
    const capitalLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    /**
     * Obtiene las certificaciones de firestore
     */
    useEffect(() => {
        const refCertifications = collection(firestore, `Instances/${AppSettings.Name}/CertificationDates`);
        const certifListener = onSnapshot(refCertifications, snap => {
            const certificationsList = snap.docs.map((d) => d.data() as Certification);
            setCertDates(orderBy(certificationsList, 'DateTime', 'asc').map((d, index) => {
                return {
                    ...d,
                    id: index,
                }
            }));
        });

        return () => {
            certifListener();
        }
    }, [AppSettings.Name])

    /**
     * Para cancelar y limpiar todos los campos
     */
    const handleCancel = () => {
        setCertification(undefined);
        setOpenDelete(undefined);
    }

    /**
     * Guarda la certificación
     */
    const handleSave = async () => {
        try {
            if (certification && certification.ID.trim() !== '') {
                const tipRef = doc(firestore, `Instances/${AppSettings.Name}/CertificationDates/${certification.ID}`);
                await setDoc(tipRef, certification);
                handleCancel();
            } else {
                const q = query(collection(firestore, `Instances/${AppSettings.Name}/CertificationDates`), where('SVC', '==', certification!.SVC));
                const snap = await getDocs(q)
                if (snap.empty) {
                    const newCertifRef = await addDoc(collection(firestore, `Instances/${AppSettings.Name}/CertificationDates`), certification);
                    await updateDoc(newCertifRef, { ID: newCertifRef.id })
                    certification!.ID = newCertifRef.id;
                    handleCancel();
                }
                else {
                    showMessage({
                        text: 'El SVC ya esta ocupado para otra fecha',
                        severity: 'error',
                    });
                    return;
                }
            }
            showMessage({
                severity: 'success',
                text: `Fecha ${certification!.ID ? 'editada' : 'creada'} exitosamente`
            })
        } catch (e) {
            showMessage({
                severity: 'error',
                text: 'Ocurrió un error al guardar, por favor intente mas tarde o contacte al administrador'
            })
        }

    }

    /**
     * Elimina certificación de BD
     */
    const handleDelete = async () => {
        try {
            const refCertif = doc(
                firestore,
                `Instances/${AppSettings.Name}/CertificationDates/${openDelete!.ID}`
            )
            await deleteDoc(refCertif);
            showMessage({
                severity: 'success',
                text: 'Fecha eliminada exitosamente',
            })
            setOpenDelete(undefined);
            handleCancel();
        } catch (e) {
            showMessage({
                text: 'Ocurrió un error al borrar la fecha, por favor intente mas tarde o contacte al administrador',
                severity: 'error',
            })
        }
    }

    /**
     * Descarga de plantilla
     */
    const downloadTemplate = () => {
        const fileRef = ref(storage, `Clients/${AppSettings.Name}/FileTemplates/plantilla_fecha_de_certificacion.xlsx`);
        getDownloadUrl(fileRef.fullPath)
            .then((url) => {
                const link = document.createElement("a");
                link.download = `plantilla_fecha_de_certificacion.xlsx`;
                link.href = url;
                link.click();
            })
            .catch((error) => {
                alert("Error descargando el archivo")
                console.error("Error al obtener la URL de descarga:", error);
            });
    }

    if (certDates)
        return (
            <CertificationsTableView
                gridCols={generarColumnas()}
                certDates={searchResults ? searchResults : certDates}
                setSearch={setSearchQuery}
                search={searchQuery}
                handleCancel={handleCancel}
                handleDelete={handleDelete}
                handleSave={handleSave}
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
                showButton={showButton}
                canSave={canSave}
                certification={certification}
                setCertification={setCertification}
                downloadTemplate={downloadTemplate}
            />
        );
    else
        return (
            <Grid container justifyContent="center">
                <LinearProgress
                    color="secondary"
                    sx={{ width: "75%" }}
                />
            </Grid>
        )
}

export default CertificationsTable;