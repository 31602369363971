import DHLRouter from './DHL';
import DefaultRouter from './Default';

export const GetInstanceRouter = (instance: string)=>{
    switch (instance) {
        case 'DHL':
            return <DHLRouter />
        default:
            return <DefaultRouter />
    }
}