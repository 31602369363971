import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { Certification } from "../../../../../../interfaces/certifications";

interface Props {
    openDelete: Certification | undefined;
    setOpenDelete: (e: Certification | undefined) => void;
    handleCancel: () => void;
    handleDelete: () => void;
}

const DeleteCertificationDialog: React.FC<Props> = ({ openDelete, setOpenDelete, handleCancel, handleDelete }) => {
    return (
        <Dialog
            open={openDelete !== undefined}
            onClose={() => setOpenDelete(undefined)}
        >
            <DialogContent>
                <Grid container sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        <Typography>
                            ¿Esta seguro que desea eliminar la fecha seleccionada?
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    color="secondary"
                    onClick={handleCancel}
                >
                    Cancelar
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ border: "1.5px solid" }}
                    onClick={handleDelete}
                >
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default DeleteCertificationDialog;