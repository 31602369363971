import React, { Suspense, useEffect } from "react";
import { useAppContext } from "../../auth/appContext";
import { useDataContext } from "../../auth/dataContext";
import withAuth from "../../auth/withAuth";
import * as StudyPageHub from "./Instances";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid } from "@mui/material";

const StudyPage: React.FC = () => {
  const { AppSettings } = useAppContext();
  const { courseID, continuar } = useParams();
  const { state } = useDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let assignment;

  switch (AppSettings.Name) {
    case "DHL":
      //El curso que tengamos en los AppSettings... fijo
      assignment = state.assignments2!.find(
        (x) => x.Course === AppSettings.Data!.activeCourse
      );
      break;
    default:
      assignment = state.assignments2!.find((x) => x.Course === courseID);
  }

  if (AppSettings) {
    if (assignment) {
      switch (AppSettings.Name) {
        case "DHL":
          return (
            <Suspense fallback={<div>Loading...</div>}>
              <StudyPageHub.Default
                courseID={AppSettings.Data!.activeCourse}
                continuar={continuar ? true : false}
              />
            </Suspense>
          );
        default:
          return (
            <Suspense fallback={<div>Loading...</div>}>
              <StudyPageHub.Default courseID={courseID} />
            </Suspense>
          );
      }
    } else {
      //Esto no me ha salido hasta ahora...
      //Consultar la url de Study/:courseID directamente me manda a /Home
      return (
        <Grid container flexDirection={"column"}>
          <h2 className="fadeInRigth">
            Debes inscribirte para poder entrar a este curso.
          </h2>
          {courseID && (
            <Button onClick={() => navigate(`/Course/${courseID}`)}>
              Ver curso
            </Button>
          )}
        </Grid>
      );
    }
  } else {
    return <></>;
  }
};

export default withAuth(StudyPage);
