import React, { Suspense, useEffect, useState } from "react";
import { useAppContext } from "../../auth/appContext";
import withAuth from "../../auth/withAuth";
import * as StudyPlansCatalogHub from "./Instances";
import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import { StudyPlan } from "../../interfaces/studyplans";
import BackdropComponent from "../../components/Backdrop";
import { useDataContext } from "../../auth/dataContext";

const StudyPlansCatalog: React.FC = () => {
  const { AppSettings } = useAppContext();
  const { state } = useDataContext();
  const refStudyPlans = collection(
    firestore,
    `Instances/${AppSettings.Name}/StudyPlans`
  );

  const [studyPlansCollection, setStudyPlansCollection] = useState<StudyPlan[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getStudyPlans();
    window.scrollTo(0, 0);
  }, []);

  const getStudyPlans = async () => {
    let querySP: any;
    if (
      state.user.Access?.includes("content_manager") ||
      state.user.Data?.isAdmin ||
      state.user.Data?.isTester
    ) {
      //Usuario creador de contenido, puede ver borradores y archivados
      querySP = query(refStudyPlans, orderBy("CreationDate", "desc"));
    } else {
      //Usuario normal, solo puede ver los publicados
      querySP = query(
        refStudyPlans,
        where("isDraft", "==", false),
        where("isArchived", "==", false),
        orderBy("CreationDate", "desc")
      );
    }

    getDocs(querySP!)
      .then((snap) => {
        const docs = snap.docs;
        const StudyPlansList = docs.map((d: any) => d.data() as StudyPlan);
        setStudyPlansCollection(StudyPlansList);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
  };

  const createStudyPlan = async () => {
    const dt = new Date();
    const spInit: StudyPlan = {
      Name: "Nuevo plan de estudio",
      Description:
        "Describir plan de estudio, su propósito, temas a abordar, objetivos a cumplir, etc...",
      Content: [],
      inOrder: true,
      isDraft: true,
      isArchived: false,
      ApproximateDuration: 0,
      CreatedBy: state.user.UID!,
      CreationDate: Timestamp.fromDate(dt),
    };
    //Escribir el curso en Firestore
    const newStudyPlanRef = await addDoc(refStudyPlans, spInit);
    //Actualizar el ID del curso
    await updateDoc(newStudyPlanRef, { ID: newStudyPlanRef.id });

    //Actualizar UI
    spInit.ID = newStudyPlanRef.id;
    setStudyPlansCollection([spInit, ...studyPlansCollection]);
  };

  if (loading) {
    return <BackdropComponent />;
  } else {
    switch (AppSettings.Name) {
      default:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <StudyPlansCatalogHub.Default
              createStudyPlan={createStudyPlan}
              studyPlansCollection={studyPlansCollection}
            />
          </Suspense>
        );
    }
  }
};

export default withAuth(StudyPlansCatalog);
