import { useAppContext } from "../../../auth/appContext";

import * as DHL from "./DHL";
import * as Default from './Default';
import { lighten } from "@mui/material";

export const GetDrawerItems = (instance: string, selected: string, setOpen: (open: boolean) => void) => {
    switch (instance) {
        case 'DHL':
            return <DHL.GetDrawerItems selected={selected} />;
        default:
            return <Default.GetDrawerItems selected={selected} setOpen={setOpen} />;
    }
}

export const GetBottomNavigationItems = (instance: string, selected: string, setOpen: (open: boolean) => void) => {
    switch (instance) {
        case 'DHL':
            return <DHL.GetDrawerItems selected={selected} />;
        default:
            return <Default.GetBottomNavigationItems selected={selected} setOpen={setOpen} />;
    }
}

export const GetPageTitle = (instance: string, path: string) => {
    switch (instance) {
        case 'DHL':
        case 'DHL2':
            return DHL.GetPageTitle(path);
        default:
            return Default.GetPageTitle(path);
    }
}

/**
 * Obtener el valor para la elevación de la barra superior
 * @param instance El nombre de la instancia en la que estamos
 * @returns un número que representa la elevación
 */
export const GetAppbarElevation = (instance: string) => {
    const { AppSettings } = useAppContext();
    switch (instance) {
        case 'DHL':
        case 'DHL2':
            return AppSettings.DesignSettings.navbarElevation ?? 0
        default:
            return AppSettings.DesignSettings.navbarElevation ?? 1
    }
}
/**
  * Obtener el color que se le debe de aplicar a la barra superior
  * @param instance El nombre de la instancia en la que estamos
  * @returns un string que representa el color
  */
export const GetAppbarSX = (instance: string) => {
    const { AppSettings } = useAppContext();


    switch (instance) {
        case 'DHL':
        case 'DHL2':
            return DHL.AppbarSX;
        case 'Softtek':
            return { background: AppSettings.DesignSettings.Palette['navbar']['upper'] ?? 'white' }
        default:
            return { background: AppSettings.DesignSettings.Palette['navbar']['upper'] ?? AppSettings.DesignSettings.Palette['secondary']['main'] }
    }
}

/**
 * Obtener el color que se le debe de aplicar al drawer de navegación lateral
 * @returns un string que representa el color
 */
export const GetNavSX = () => {
    const { AppSettings } = useAppContext();

    if (AppSettings.DesignSettings.Palette) {
        switch (AppSettings.Name) {
            case 'Softtek':
                return lighten(AppSettings.DesignSettings.Palette['navbar']['side'] ?? 'white', .1);
            default:
                return AppSettings.DesignSettings.Palette['navbar']['side'] ?? 'white';
        }
    } else {
        return "#fff"
    }
}