import { Routes, Route, Navigate } from "react-router-dom";

// Components
import HomePage from "../../../../pages/Home";
import CalendarPage from "../../../../pages/Calendar";
import RetaPage from "../../../../pages/Z_Custom/DHL/Reta";
import Activities from "../../../../pages/Z_Custom/DHL/Activities";
import ReportsPage from "../../../../pages/Reports/index";
import Announcements from "../../../../pages/Announcements/Instances/DHL";
import { useDataContext } from "../../../../auth/dataContext";
import StudyTipModal from "./StudyTipModal";
import Study from "../../../../pages/Study";
import GameQuizClient from "../../../../pages/Z_Custom/DHL/Reta/GameQuiz/Client";
import Catalog from "../../../../pages/Catalog";
import CoursePage from "../../../../pages/Course";
import AreYouReady from "../../../../pages/Z_Custom/DHL/AreYouReady";
import Admin from "../../../../pages/Admin";
import DocumentsPage from "../../../../pages/Documents"
import Documents from "../../../../pages/Documents";
import { useContext } from "react";
import { AppContext } from "../../../../auth/appContext";
import { Timestamp } from "firebase/firestore";
import { routes } from "../../../../interfaces/routeItems";

const DHLRouter = () => {
  const { state } = useDataContext();
  const { AppSettings } = useContext(AppContext);

  const getRoutesForRole = () => {
    //Tenemos datos del usuario?
    if (state.user && state.user.Data) {

      const rutas: routes[] = [
        {
          route: <Route path="*" element={<Navigate to="/Home" />} />,
          role: 'leader|yis|courier|manager',
        },
        {
          route: <Route path="*" element={<Navigate to="/Reports" />} />,
          role: 'admin',
        },
        {
          route: <Route path="/Home" element={<HomePage />} />,
          role: 'leader|yis|courier|manager',
        },
        {
          route: <Route path="/Calendar" element={<CalendarPage />} />,
          role: 'leader|yis|manager',
        },
        {
          route: <Route path="/Study" element={<Study />} />,
          role: 'leader|yis|manager',
        },
        {
          route: <Route path="/Activities" element={<Activities />} />,
          role: 'leader|yis|manager',
        },
        {
          route: <Route path="/Reta" element={<RetaPage />} />,
          role: 'leader|yis|manager',
        },
        {
          route: <Route path="/AreYouReady" element={<AreYouReady />} />,
          role: 'leader|yis|manager',
        },
        {
          route: <Route path="/Announcements" element={<Announcements />} />,
          role: 'leader|courier|manager|yis',
        },
        {
          route: <Route path="/Reports" element={<ReportsPage />} />,
          role: 'admin|manager',
        },
        {
          route: <Route path="/Catalog" element={<Catalog />} />,
          role: 'leader|yis|manager',
        },
        {
          route: <Route path="/Course/:courseID" element={<CoursePage />} />,
          role: 'leader|yis|manager',
        },
        {
          route: <Route path="/Admin" element={<Admin />} />,
          role: 'admin',
        },
        {
          route: <Route path="/Documents" element={<DocumentsPage />} />,
          role: 'admin',
        },
      ]

      let role = "";
      if (state.user.Data.isCourier) {
        role = "courier";
      } else if (state.user.Data.isLeader) {
        role = "leader";
      } else if (state.user.Data.isManager) {
        role = "manager";
      } else if (state.user.Data.isAdmin) {
        role = "admin";
      } else if (state.user.Data.isTester) {
        role = "tester";
      } else if (state.user.Data.isYIS) {
        role = "yis";
      }

      let _rutas = rutas.filter(
        (ruta) => ruta.role.includes(role)).map(
          (filtrado) => {
            return filtrado.route
          });
          
      if (role === '') {
        return (
          <Routes>
            <Route path="*" element={<Navigate to='/404' />} />
            <Route path="/404" element={pageNotFoundComponent} />
          </Routes>
        )
      }
      else if (!['tester'].includes(role)) {
        if ((AppSettings.Data.courseDeadline as Timestamp).toDate() < new Date() && !['manager', 'admin'].includes(role)) {
          return (
            <Routes>
              <Route path="*" element={<Navigate to="/Home" />} />
              <Route path="/Home" element={<HomePage />} />
            </Routes>
          )
        } else {
          return (
            <Routes>
              {_rutas}
            </Routes>
          )
        }
      } else {
        return (
          <Routes>
            <Route path="*" element={<Navigate to="/Home" />} />
            <Route path="/Home" element={<HomePage />} />
            <Route path="/Calendar" element={<CalendarPage />} />
            <Route path="/Study" element={<Study />} />
            <Route path="/Activities" element={<Activities />} />
            <Route path="/Reta" element={<RetaPage />} />
            <Route path="/AreYouReady" element={<AreYouReady />} />
            <Route path="/Announcements" element={<Announcements />} />
            <Route path="/Reports" element={<ReportsPage />} />
            <Route path="/Catalog" element={<Catalog />} />
            <Route path="/Course/:courseID" element={<CoursePage />} />
            <Route path="/Admin" element={<Admin />} />
            <Route path="/Documents" element={<Documents />} />
          </Routes>
        );
      }
    }
    else {
      return (
        <Routes>
          <Route path="*" element={pageNotFoundComponent} />
          <Route path="/play/:gameRoomID" element={<GameQuizClient />} />
        </Routes>
      );
    }
  };

  const pageNotFoundComponent = <h1>There's nothing here! Page not found</h1>;

  return (
    <>
      {getRoutesForRole()}
      <StudyTipModal />
    </>
  );
};

export default DHLRouter;
