import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fade: {
            animation: '$fade .8s forwards'
        },
        '@keyframes fade': {
            from: {
                opacity: 0,
            },
            to: {
                opacity: 1,
            }
        }
    }))
export default useStyles;