import {makeStyles, createStyles} from "@mui/styles";
import {Theme} from "@mui/system";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mainContainer: {
			alignItems: "center",
			backgroundColor: theme.palette.secondary.main,
			// borderRadius: "10px",
			color: "white",
			cursor: "pointer",
			display: "flex",
			gap: 16,
			justifyContent: "space-between",
			padding: "4px 16px",
		},
		nameContainer: {
			textAlign: "end",
		},
		icon: {
			fontSize: "2.5rem !important",
		},
		fontDelivery: {
			fontFamily: 'Delivery',
		}
	})
);

export default useStyles;
