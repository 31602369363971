import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles(() =>
	createStyles({
		optionCard: {
			height: '300px',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center', //horizontal
            alignItems: 'center', //vertical
            padding: '15px'
		},
        optionsContainer:{
            padding: "20px"
        }
	})
);

export default useStyles;
