import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { validation } from "../../../../../../interfaces/validation";
import SheetValidation from "./sheetvalidation";

interface Props {
    openValidation: boolean;
    setOpenValidation: (e: boolean) => void;
    resultsValidation: validation;
}

const ValidationExcel: React.FC<Props> = ({ openValidation, setOpenValidation, resultsValidation }) => {
    return (
        <Dialog open={openValidation} onClose={() => { setOpenValidation(false) }} PaperProps={{ sx: { height: '500px' } }} maxWidth='xl' fullWidth >
            <DialogTitle>Ha ocurrido un error al cargar los archivos</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Se encontraron los siguientes problemas al verificar el contenido de
                    los archivos.
                </DialogContentText>
                <Grid container>
                    <Grid item xs={12} >
                        {/* Armar dinámicamente el contenido de las tabs por cada excel con errores */}
                        {Object.keys(resultsValidation).map((key) => (
                            <SheetValidation results={resultsValidation[key]} key={key} />
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenValidation(false)} variant="outlined" color="secondary">
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default ValidationExcel;