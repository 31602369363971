import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useAppContext } from "../../auth/appContext";
import { CircularProgressHelper } from "../../helpers/ColorHelpers/colorHelpers";

// Component for loading pages
const BackdropComponent: React.FC = () => {
	const { AppSettings } = useAppContext();
	return (
		<Backdrop
			open={true}
			sx={{
				backgroundColor: "rgba(0,0,0,0.1)",
			}}>
			{CircularProgressHelper(AppSettings.Name)}
		</Backdrop>
	);
};

export default BackdropComponent;
