import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, Input, TextField, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridExpandMoreIcon } from "@mui/x-data-grid";
import { StudyTip } from "../../../../../../interfaces/course";
import useStyles from "../../style";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Timestamp, addDoc, collection, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { Download, UploadFile } from "@mui/icons-material";
import { ref } from "firebase/storage";
import { useAppContext } from "../../../../../../auth/appContext";
import { firestore, storage } from "../../../../../../firebase";
import { getDownloadUrl } from "../../../../../../helpers";
import { validation } from "../../../../../../interfaces/validation";
import { isEmpty } from "lodash";
import * as XLSX from 'xlsx'
import { validarCargaStudyTips } from "./validateFile";
import ValidationExcel from "../../CertificationsTable/Dialogs/excel";
import { useShowMessage } from "../../../../../../providers/MessageProvider/MessageProvider";
import { useDataContext } from "../../../../../../auth/dataContext";

interface Props {
    columns: GridColDef[];
    rows: StudyTip[];
    studyTip: StudyTip | undefined;
    setStudyTip: (e: StudyTip | undefined) => void
    handleSave: () => void;
    handleFile: (e: React.FormEvent<HTMLInputElement>) => void
    openDelete: StudyTip | undefined;
    handleDelete: () => void;
    file: File | undefined
    handleClose: () => void;
}
const StudyTipsView: React.FC<Props> = ({
    columns,
    rows,
    setStudyTip,
    studyTip,
    handleSave,
    handleFile,
    openDelete,
    handleDelete,
    file,
    handleClose
}) => {
    const classes = useStyles();
    const { AppSettings } = useAppContext();
    const { dispatch, state } = useDataContext();
    const { showMessage } = useShowMessage();
    const [pageSize, setPageSize] = useState<number>(25);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [template, setTemplate] = useState<File>();
    const [validationResults, setValidationResults] = useState<validation>({});
    const [openResults, setOpenResults] = useState<boolean>(false);

    const canSave = () => {
        if (studyTip?.HasImage)
            return studyTip.Description.trim() !== '' &&
                studyTip.Title.trim() !== '' &&
                file !== undefined
        else
            return studyTip?.Description.trim() !== '' &&
                studyTip?.Title.trim() !== ''
    }

    /**
     * Descarga de plantilla
     */
    const downloadTemplate = async () => {
        const fileRef = ref(storage, `Clients/${AppSettings.Name}/FileTemplates/plantilla_tips_de_estudio.xlsx`);
        getDownloadUrl(fileRef.fullPath).then((url) => {
            const link = document.createElement("a");
            link.download = `plantilla_tips_de_estudio.xlsx`;
            link.href = url;
            link.click();
        }).catch((error) => {
            alert("Error descargando el archivo")
            console.error("Error al obtener la URL de descarga:", error);
        });
    }

    /**
    * Listener del archivo que valida la extensión antes de habilitar botón de carga
    * @param e Archivo
    */
    const handleFileXLSX = (e: React.FormEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files || [];
        if (files.length > 0) {
            const files = (e.target as HTMLInputElement).files || [];
            const file = files[0];
            if (!file.name.endsWith('xlsx')) {
                showMessage({
                    text: "Este archivo no es compatible, por favor verifique que la extensión sea .xlsx",
                    severity: 'error',
                });
                setTemplate(undefined);
            } else {
                setTemplate(file);
            }
        } else {
            setTemplate(undefined)
        }
    }

    /**
     * Verificar carga archivo excel
     */
    const handleSaveXLSX = async () => {
        if (template) {
            setSaving(true);
            const fileData = await template.arrayBuffer();
            const workbook = XLSX.read(fileData, { dateNF: 'mm-dd-yyyy hh:mm', cellDates: true });
            const sheets = workbook.Sheets;
            const nameSheets = Object.keys(sheets);

            let resultsValidation: validation = {};
            resultsValidation["studyTips"] = validarCargaStudyTips(sheets, nameSheets);

            if (!isEmpty(resultsValidation["studyTips"])) {
                setValidationResults(resultsValidation);
                setOpenResults(true);
                setSaving(false);
            } else {
                const data = XLSX.utils.sheet_to_json(sheets[nameSheets[0]], { header: 1, }) as any[][];

                if (!isEmpty(resultsValidation["studyTips"])) {
                    setValidationResults(resultsValidation);
                    setOpenResults(true);
                } else {
                    await saveExceltoDB(data);
                }
            }
            setSaving(false)
        }
    }

    /**
     * Guardar en base de datos todos los tips de estudio
     * @param data data obtenida de validación excel
     */
    const saveExceltoDB = async (data: any[][]) => {
        try {
            let studyTipsArr: StudyTip[] = [];
            for (let row = 1; row < data.length; row++) {
                let studyTip: StudyTip = {
                    Title: data[row][0],
                    Description: data[row][1],
                    From: data[row][2],
                    HasImage: false,
                    Img: '',
                    ID: ''
                }
                studyTipsArr.push(studyTip);
            }

            const tempData = { ...state };
            const studyTipsRef = collection(firestore, `Instances/${AppSettings.Name}/StudyTips`);
            for (let studyTip of studyTipsArr) {
                const newStudyTipRef = await addDoc(studyTipsRef, studyTip);
                await updateDoc(newStudyTipRef, { ID: newStudyTipRef.id });
                tempData.studyTips?.push(studyTip);
            }
            dispatch({
                type: 'set',
                values: tempData,
            });
            setTemplate(undefined);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent='flex-end'>
                    <Grid item xs={12} lg={2} md={3}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={downloadTemplate}
                            fullWidth
                        >
                            Descargar plantilla <Download />
                        </Button>

                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item xs={2}>
                        <Button
                            variant='contained'
                            onClick={() => {
                                setStudyTip({
                                    Description: '',
                                    From: Timestamp.fromDate(new Date()),
                                    ID: '',
                                    Img: '',
                                    Title: '',
                                    HasImage: false,
                                })
                            }}
                        >
                            Agregar tip de estudio
                        </Button>
                    </Grid>
                    <Grid item xs={7}>
                        <Accordion
                            expanded={expanded}
                            onChange={() => setExpanded(!expanded)}
                        >
                            <AccordionSummary
                                expandIcon={<GridExpandMoreIcon />}
                            >
                                <Typography sx={{ flexShrink: 0 }}>
                                    Cargar archivo Excel
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent='center' alignItems='center'
                                            sx={{
                                                padding: 1,
                                                borderStyle: 'dashed',
                                                borderWidth: '0.3vh',
                                                bgcolor: '#E5E8E8'
                                            }}
                                        >
                                            <Grid item xs={12} justifyContent='center'>
                                                <UploadFile
                                                    sx={{
                                                        color: 'gray',
                                                        width: '100%',
                                                        height: '6vh'
                                                    }} />
                                            </Grid>
                                            <Grid item xs={12} container justifyContent='center'>
                                                <Input
                                                    type='file'
                                                    size='small'
                                                    disableUnderline
                                                    onChangeCapture={handleFileXLSX}
                                                    key={template?.name}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container justifyContent='center' alignItems='center' sx={{ marginTop: 1 }}>
                                        <Button
                                            variant="contained"
                                            color='secondary'
                                            disabled={!template || saving}
                                            onClick={handleSaveXLSX}
                                        >
                                            Cargar archivo
                                        </Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    autoHeight
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowId={(row) => row.ID}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    disableSelectionOnClick
                    disableColumnSelector
                    pagination
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: "Filas por página"
                        }
                    }}
                    className={classes.table}
                />
            </Grid>

            {/* DIALOGO AGREGAR/EDITAR */}
            <Dialog
                open={studyTip !== undefined}
                onClose={handleClose}
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography
                                align='center'
                                sx={{
                                    fontWeight: "bold"
                                }}
                                variant="h5"
                            >
                                {studyTip && studyTip.ID !== '' ? `Editar` : 'Agregar nuevo'} tip de estudio
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Título"
                                variant="outlined"
                                fullWidth
                                value={studyTip?.Title}
                                onChange={(e) => {
                                    studyTip &&
                                        setStudyTip({
                                            ...studyTip,
                                            Title: e.target.value
                                        })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Descripción"
                                variant="outlined"
                                rows={3}
                                fullWidth
                                multiline
                                value={studyTip?.Description}
                                onChange={(e) => {
                                    studyTip &&
                                        setStudyTip({
                                            ...studyTip,
                                            Description: e.target.value
                                        })
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                            >
                                <DateTimePicker
                                    value={studyTip?.From.toDate()}
                                    label="Desde..."
                                    onChange={(newValue: Date | null) => {
                                        if (newValue !== null && studyTip) {
                                            setStudyTip({
                                                ...studyTip,
                                                From: Timestamp.fromDate(newValue)
                                            })
                                        }
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            fullWidth
                                            {...params}
                                        />
                                    }
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems='center'>
                                <Grid item xs={3}>
                                    <FormControlLabel
                                        sx={{
                                            "& .MuiSvgIcon-root": {
                                                color: "#000000"
                                            }
                                        }}
                                        label="Imagen"
                                        labelPlacement="end"
                                        control={
                                            <Checkbox
                                                checked={studyTip?.HasImage}
                                                onChange={() => {
                                                    studyTip &&
                                                        setStudyTip({
                                                            ...studyTip,
                                                            HasImage: !studyTip?.HasImage,
                                                        })
                                                }}
                                            />}
                                    />
                                </Grid>
                                <Grid item xs={9}>
                                    {studyTip && studyTip.HasImage &&
                                        <Input
                                            type='file'
                                            disableUnderline
                                            size='small'
                                            inputProps={{
                                                accept: 'image/*'
                                            }}
                                            onChangeCapture={handleFile}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        {studyTip?.Img.trim() !== '' &&
                            <Grid item xs={12} container justifyContent='center' alignItems='center'>
                                <Box component='img'
                                    src={studyTip?.Img}
                                    alt='undefined'
                                    sx={{
                                        maxHeight: '250px',
                                        display: 'block',
                                        objectFit: 'contain',
                                        overflow: 'hidden',
                                        width: '100%',
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        disabled={!canSave()}
                        variant="outlined"
                        color="secondary"
                        onClick={handleSave}
                    >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* DIALOG ELIMINAR */}
            <Dialog
                open={openDelete !== undefined}
                onClose={handleClose}
            >
                <DialogContent>
                    <Grid container sx={{ p: 2 }}>
                        <Grid item xs={12}>
                            <Typography>
                                ¿Esta seguro que desea eliminar el tip seleccionado?
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={handleClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={handleDelete}
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            <ValidationExcel
                openValidation={openResults}
                setOpenValidation={setOpenResults}
                resultsValidation={validationResults}
            />
        </Grid>
    )
}
export default StudyTipsView;