import { Timestamp, collection, getDocs } from "firebase/firestore";
import { Report } from "../../interfaces";
import { Course } from "../../interfaces/course";
import { firestore } from "../../firebase";

export const getCourses = async (instance: string): Promise<Course[]> => {
	const ref = collection(firestore, `Instances/${instance}/Courses`);

	let data: Course[] = await getDocs(ref).then((snap) => {
		return snap.docs.map((doc) => {
			const data = doc.data() as Course;
			data.ID = doc.id;
			return data;
		});
	});

	return data;
};

 
export const getReports = async (instance: string, courseId: string, StartDate?: Timestamp): Promise<Report[]> => {
	try {
		const ref = collection(firestore, `Instances/${instance}/Courses/${courseId}/Reports`);
		let reports: any[] = [];
		const snap = await getDocs(ref);
		if (!snap.empty) {
			reports = snap.docs.map((doc) => { const report = doc.data() as Report;
				if(StartDate) report.StartDate = StartDate
				return report; });
		} else {
			reports = [];
		}
		return reports;
	} catch (e) {
		console.log('Error getReports: ', e);
		return [];
	}
};
