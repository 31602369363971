import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Slide,
  Tooltip,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { getIcon } from "../../../../../helpers/iconHelper";
import {
  CheckCircleOutlineOutlined,
  ExpandMore,
  HighlightOffOutlined,
} from "@mui/icons-material";
import useStyles from "../style";
import React, { useEffect, useState } from "react";
import { MaterialItem } from "../../../../../interfaces/course";
import { ResultTest } from "../../../../../interfaces/ui";
import _ from "lodash";
import { useTheme } from "@mui/material/styles";
import { useAppContext } from "../../../../../auth/appContext";
import {
  getButtonMainAction,
  getButtonSecondaryAction,
} from "../../../../../helpers/ColorHelpers/colorHelpers";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  material: MaterialItem;
  handleAnswer: (idA: string, idQ: number) => void;
  endTest: () => void;
  setOpenModal: (value: boolean) => void;
  openModal: boolean;
  isApprove: boolean;
  examen: boolean; //la vista es de Study?
  questions: any;
  calificacion: number;
  correctAnsw: number;
  setShowResults: (value: boolean) => void;
  showResults: boolean;
  handleReset: () => void;
  reset: () => void;
  back: () => void;
  results: ResultTest[];
  temasReforzar: any;
  setShowTemasReforzar: (value: boolean) => void;
  showtemasReforzar: boolean;
}

const PresentingTest_View: React.FC<Props> = ({
  material,
  handleAnswer,
  questions,
  endTest,
  setOpenModal,
  openModal,
  isApprove,
  examen,
  calificacion,
  correctAnsw,
  setShowResults,
  showResults,
  handleReset,
  reset,
  back,
  results,
  temasReforzar,
  setShowTemasReforzar,
  showtemasReforzar,
}) => {
  const classes = useStyles();
  //{total: num}
  const [acordeon, setAcordeon] = useState<any>();
  const [maxErrores, setMaxErrores] = useState<number>(0); //maximo de errores por módulo
  const theme = useTheme();
  const { AppSettings } = useAppContext();

  useEffect(() => {
    if (temasReforzar) {
      // console.log(temasReforzar);
      let tempAcordeon: any = {};
      let max = 0;

      Object.keys(temasReforzar).forEach((module, index) => {
        let totalModule = 0;
        Object.keys(temasReforzar[module]).forEach((topic) => {
          totalModule += temasReforzar[module][topic];
        });

        if (max < totalModule) max = totalModule;
        // console.log(max);

        tempAcordeon[index] = { total: totalModule };
      });

      // console.log(tempAcordeon);
      setAcordeon(tempAcordeon);
      setMaxErrores(max);
    }
  }, [temasReforzar]);

  return (
    <>
      {/* formulario del examen */}
      <Box>
        {(material.Questions ?? []).map((q, index) => (
          <Grid
            container
            key={`${q.Name}-${index}`}
            className={classes.fadeInDown}
            style={{
              animationDelay: `${index * 0.1}s`,
            }}
          >
            <Grid item xs={12} marginY={1}>
              <Grid item xs={12}>
                <Typography variant="h6" fontSize={18}>
                  {index + 1}. {q.Name}
                </Typography>
              </Grid>
              <FormControl>
                <RadioGroup
                  onChange={(e) => handleAnswer(e.target.value, index)}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  {q.Options.map((o, index) => (
                    <FormControlLabel
                      key={index}
                      value={index}
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked,
                          }}
                          size="small"
                          color={"default"}
                        />
                      }
                      label={o}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Divider sx={{ pt: 1 }} />
            </Grid>
          </Grid>
        ))}

        <Grid container justifyContent="end" marginTop={4}>
          <Button
            disabled={
              questions.length !== material.Questions!.length ||
              questions.includes(undefined)
            }
            className={classes.submitButton}
            variant="contained"
            onClick={endTest}
            color="secondary"
          >
            Finalizar
          </Button>
        </Grid>
      </Box>

      {/* Modal de Calificación */}
      <Dialog
        open={openModal}
        onClose={(e, reason) => (!reason ? setOpenModal(false) : null)}
        sx={{
          position: "absolute" as "absolute",
          border: "0px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius:
            AppSettings.DesignSettings.borderRadius !== undefined
              ? `${AppSettings.DesignSettings.borderRadius}px` //Lo que diga la configuración de la instancia
              : "0px", //0 por default,
        }}
        closeAfterTransition
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <Typography
            className={classes.fadeInDown}
            // variant="h6"
            textAlign="center"
            fontWeight={700}
            fontSize={22}
          >
            {isApprove ? "¡Felicidades!" : "Lo sentimos, no aprobaste"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            className={classes.fadeInDown}
            textAlign="center"
            fontSize={18}
          >
            {isApprove ? "¡Has aprobado!" : "Inténtalo de nuevo"}
          </Typography>
          {examen ? (
            <Typography
              className={classes.fadeInDown}
              variant="h5"
              textAlign="center"
            >
              Calificación: <b>{`${calificacion.toFixed(0)}`}</b>
            </Typography>
          ) : (
            <Typography
              className={classes.fadeInDown}
              variant="h5"
              textAlign="center"
            >
              Resultado {`${correctAnsw}/${material.Questions!.length}`}
            </Typography>
          )}
          <Grid container justifyContent="center">
            {isApprove ? (
              <CheckCircleOutlineOutlined
                className={classes.checkIcon}
                color="success"
              />
            ) : (
              <HighlightOffOutlined
                className={classes.checkIcon}
                color="error"
              />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={[classes.submitButton, classes.fadeInUp].join(" ")}
            variant={getButtonSecondaryAction(AppSettings.Name).variant}
            color={getButtonSecondaryAction(AppSettings.Name).color}
            onClick={
              isApprove
                ? () => {
                    handleReset();
                    reset();
                    back();
                  }
                : () => {
                    setOpenModal(false);
                    handleReset();
                  }
            }
          >
            Cerrar
          </Button>
          <Button
            className={[classes.submitButton, classes.fadeInUp].join(" ")}
            variant={getButtonMainAction(AppSettings.Name).variant}
            color={getButtonMainAction(AppSettings.Name).color}
            onClick={() => {
              setOpenModal(false);
              setShowResults(true);
            }}
          >
            Ver resultados
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialogo de retroalimentación */}
      <Dialog
        open={showResults}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowResults(false)}
      >
        <DialogTitle>{"Resultados"}</DialogTitle>
        <DialogContent>
          <List>
            {results.map((res, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  {res.approved ? (
                    <Icon color="success">
                      {getIcon("DHL", "check", "outlined")}
                    </Icon>
                  ) : (
                    <Icon color="error">
                      {getIcon("DHL", "close", "outlined")}
                    </Icon>
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={`${index + 1}. ${res.question.Name}`}
                  secondary={`R: ${res.userAnswer ?? ""}`}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          {examen ? (
            <Button
              color={getButtonMainAction(AppSettings.Name).color}
              variant={getButtonMainAction(AppSettings.Name).variant}
              onClick={
                isApprove
                  ? () => {
                      handleReset();
                      reset();
                      back();
                    }
                  : () => {
                      setShowResults(false);
                      handleReset();
                    }
              }
            >
              Aceptar
            </Button>
          ) : (
            <>
              <Button
                color={getButtonMainAction(AppSettings.Name).color}
                variant={getButtonSecondaryAction(AppSettings.Name).variant}
                onClick={() => {
                  endTest();
                  setShowResults(false);
                }}
              >
                Cerrar
              </Button>
              <Button
                color={getButtonMainAction(AppSettings.Name).color}
                variant={getButtonMainAction(AppSettings.Name).variant}
                onClick={() => {
                  handleReset();
                  reset();
                  back();
                }}
              >
                Reiniciar
              </Button>
              {/* solo muestra este botón cuando es sección ¿Estás listo? */}
              {temasReforzar && !_.isEmpty(temasReforzar) && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setShowTemasReforzar(true);
                  }}
                >
                  Ver resultados por tema
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* Dialogo Temas a reforzar, solo se muestra en la sección ¿Estás listo? */}
      <Dialog
        open={showtemasReforzar}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowTemasReforzar(false)}
      >
        <DialogTitle>{"Resultados por tema"}</DialogTitle>
        <DialogContent>
          <List>
            {temasReforzar &&
              acordeon &&
              Object.keys(temasReforzar).map((modulo, index) => (
                <Accordion
                  key={index}
                  disableGutters
                  sx={{ boxShadow: "none" }}
                  classes={{
                    root: classes.MuiAccordionroot,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    sx={{
                      borderTop: 1.5,
                      borderColor: theme.palette.grey[500],
                    }}
                  >
                    <Grid container>
                      <Grid item xs={10}>
                        <Typography
                          component={"span"}
                          variant="body1"
                          fontWeight={"bold"}
                        >
                          <Grid container alignItems="center">
                            {acordeon[index].total === maxErrores && (
                              <Tooltip
                                title="¡Toma nota! Puedes reforzar tus conocimientos en este módulo para tener mejores resultados."
                                placement="top-start"
                              >
                                <Grid
                                  container
                                  item
                                  xs={1}
                                  sx={{
                                    color: theme.palette.secondary.main,
                                  }}
                                  alignItems="center"
                                >
                                  {getIcon("DHL", "alert", "filled")}
                                </Grid>
                              </Tooltip>
                            )}
                            {"Módulo " + (index + 1) + ": " + modulo}
                          </Grid>
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography variant="body2" textAlign={"right"}>
                          {acordeon[index].total} errores
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {Object.keys(temasReforzar[modulo]).map(
                        (topic, index) => (
                          <ListItem key={topic}>
                            <ListItemText
                              primary={"Tema " + (index + 1) + ": " + topic}
                              sx={{ fontVariant: "body2" }}
                            />
                            <ListItemIcon>
                              <Typography variant="body2" textAlign={"right"}>
                                {temasReforzar[modulo][topic]}
                                {temasReforzar[modulo][topic] === 1
                                  ? " error"
                                  : " errores"}
                              </Typography>
                            </ListItemIcon>
                          </ListItem>
                        )
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}

            <Accordion
              key={100000}
              disableGutters
              sx={{ boxShadow: "none" }}
              classes={{
                root: classes.MuiAccordionroot,
              }}
            >
              <AccordionSummary
                // expandIcon={<ExpandMore />}
                sx={{
                  borderTop: 1.5,
                  borderColor: theme.palette.grey[500],
                }}
              ></AccordionSummary>
            </Accordion>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setShowTemasReforzar(false);
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PresentingTest_View;
