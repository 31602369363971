import React, { createContext, useContext, useEffect, useState } from "react";
import { ClientSettings, WebsiteContext } from "../interfaces";
import { query, collection, where, getDocs } from "@firebase/firestore";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import { firestore } from "../firebase";
import { DataProvider } from "./dataContext";
import BackdropComponent from '../components/Backdrop';
import { getTema } from "./themeHelper";

export const AppContext = createContext<WebsiteContext>({
	AppSettings: {} as ClientSettings,
});

export const WebsiteContextLoader: React.FC = ({ children }: any) => {
	const [state, setState] = useState<WebsiteContext | undefined>();
	const [theme, setTheme] = useState<Theme>(createTheme());


	useEffect(() => {
		//Query al cliente, según la url
		// Obtener url
		var getUrl = window.location;
		var baseUrl = getUrl.protocol + "//" + getUrl.host;
		// referencia a la instancia
		const instancesRef = collection(firestore, "Instances");
		// buscar instancias que coincidan con la url
		const q = query(instancesRef, where("Config.Hosting", "array-contains", baseUrl));
		// obtener instancia
		getDocs(q).then((snap) => {
			if (!snap.empty) {
				const data = snap.docs[0].data() as ClientSettings;
				setState({
					AppSettings: data,
				});
				setTheme(
					getTema(data)
				);
			} else {
				//No hay instancias configuradas para este dominio
				//Redirigir a mosaico de fridaplatform.online ?
				//Mostrar página 404?
			} 
		})   
			.catch(e => {
				console.log(e);
				alert("Error del servicio");
			});
	}, []);

	if (state) {
		document.getElementById("icon")!.setAttribute("href", state.AppSettings.DesignSettings.Images.Icon);
		document.getElementById("webTitle")!.innerText = state.AppSettings.Name;

		return (
			<ThemeProvider theme={theme}>
				<AppContext.Provider value={state}>
					<DataProvider>
						{children}
					</DataProvider>
				</AppContext.Provider>
			</ThemeProvider>
		);
	} else {
		//Loader
		return <BackdropComponent />;
	}
};

export const useAppContext = () => useContext(AppContext);
