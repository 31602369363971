import {makeStyles, createStyles} from "@mui/styles";
import {Theme} from "@mui/system";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderRadius: 8,
			boxShadow: "0px 2px 4px #00000030",
			cursor: "pointer",
			height: "9rem",
			transition: "all .3s",
			"&:hover": {
				boxShadow: "0px 2px 10px #00000030",
				transform: "translateY(-2px)",
			},
		},
		description: {
			display: "-webkit-box",
			lineClamp: 3,
			overflow: "hidden",
			textOverflow: "ellipsis",
			"-webkit-box-orient": "vertical",
			"-webkit-line-clamp": 3,
		},
	})
);

export default useStyles;
