import { BrowserRouter } from "react-router-dom"
import { useAppContext } from "../../auth/appContext"
import { GetInstanceRouter } from "./Instances"

/**
 * Elemento de Router para cuando el usuario no tiene sesión iniciada
 * @returns Rutas que son públicas para la instancia en cuestión
 * (basado en la URL como es normal)
 */
const PublicRouter = () => {
    const { AppSettings } = useAppContext();

    return <BrowserRouter>
        {GetInstanceRouter(AppSettings.Name)}
    </BrowserRouter>
}

export default PublicRouter;