import {makeStyles, createStyles} from "@mui/styles";
import {Theme} from "@mui/system";
import { GetNavSX } from "./Instances";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		MuiDrawer: {
			backgroundColor: GetNavSX() + " !important",
			border: "none !important",
		},
		listItem: {
			borderRadius: "100px 0 0 100px !important",
			marginTop: "12px !important",
			backgroundColor: "transparent !important",
		},
		listItemSelected: {
			borderRadius: "100px 0 0 100px !important",
			marginTop: "12px !important",
			backgroundColor: "#f2f2f2 !important",
		},
		principalContainer: {
			minHeight: "99%",
			position: "relative",
		},
		mainContainerBody: {
			margin: "0",
			minHeight: "750px",
			marginBottom: "20px",
		},
		footer: {
			display: "flex",
			justifyContent: "center",
			alignItems: "flex-end",
			position: "absolute",
			bottom: "0px",
			width: "100%",
			height: "20px",
		},
		centerDiv: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		footerImg: {
			[theme.breakpoints.down("md")]: {
				width: "30%",
			},
			[theme.breakpoints.down("sm")]: {
				width: "40%",
			},
		},
	})
);

export default useStyles;
