import { useEffect, useState } from "react";
import ModalComponent from "../../../Modal";
import { StudyTip } from "../../../../interfaces/course";
import { Button, Typography } from "@mui/material";
import { useDataContext } from "../../../../auth/dataContext";
import useStyles from './style';
import { orderBy } from "lodash";

const StudyTipModal = () => {
  const { state } = useDataContext();
  const classes = useStyles();
  const [showTip, setShowTip] = useState<boolean>(!sessionStorage.getItem("showTip"));
  const [imageUrl, setImageUrl] = useState<string>("");
  const [studyTip, setStudyTip] = useState<StudyTip>();

  useEffect(() => {
    if (state && state.studyTips) {

      // Ordenar study tips for fecha de manera ascendente
      const temp = orderBy(
        // Filtrar unicamente los tips que sean inferiors a la fecha actual
        state.studyTips.filter(
          d => d.From.toDate() < new Date()),
        'From',
        'asc'
      )

      // Mostrar unicamente el study tip mas reciente
      const visibleTip = temp[temp.length - 1];

      // Si existe study tip y tiene url obtener la imagen
      if (visibleTip && visibleTip.Img) {
        setImageUrl(visibleTip.Img);
      }
      // Setear el tip visible para hacer el render
      setStudyTip(visibleTip);

    }
  }, [state]);

  const handleCloseTip = () => {
    setShowTip(false);
    sessionStorage.setItem("showTip", "false");
  };

  return <ModalComponent
    open={showTip && Boolean(studyTip)}
    handleClose={handleCloseTip}
  >
    <>
      <Typography
        fontSize={18}
        fontWeight='bold'
        textAlign='center'>
        {studyTip?.Title ?? ""}
      </Typography>
      <div
        className={classes.centerDiv}
        style={{
          marginBottom: 16,
        }}>
        <img
          src={imageUrl}
          alt='study_tip_image'
          width="100%"
          style={{
            objectFit: 'contain',
            maxHeight: '250px'
          }}
        />
      </div>
      <Typography>
        {studyTip?.Description ?? ""}
      </Typography>
      <div
        className={classes.centerDiv}
      >
        <Button
          onClick={handleCloseTip}
          variant="outlined"
          color="secondary"
        >
          Cerrar
        </Button>
      </div>
    </>
  </ModalComponent>
}

export default StudyTipModal;