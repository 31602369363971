import DocumentCard from "./DocumentCard";
import { storage } from "../../../../firebase";
import { useEffect, useState } from "react";
import { getDownloadURL, getMetadata, listAll, ref } from "@firebase/storage";
import { StorageFile } from "./Interfaces";
import { Button, Grid, List, Typography } from "@mui/material";
import { CloudUpload, RemoveCircleOutline } from "@mui/icons-material";
import {
  deleteObject,
  getStorage,
  uploadBytesResumable,
} from "firebase/storage";
import { useShowMessage } from "../../../../providers/MessageProvider/MessageProvider";
import DeleteDocumentDialog from "./DeleteDocumentDialog";
import { useDataContext } from "../../../../auth/dataContext";
import { doc } from "firebase/firestore";
const DocumentsDHL: React.FC = () => {
  const { state } = useDataContext();
  const { showMessage } = useShowMessage();
  const [fileDetails, setFileDetails] = useState<StorageFile[]>([]);
  const [preview, setPreview] = useState(undefined);
  const [newDoc, setNewDoc] = useState<File | undefined>(undefined);
  const [currentDoc, setCurrentDoc] = useState<undefined | StorageFile>(
    undefined
  );

  const handleFileChange = (event: any) => {
    const selectedFile = event.target.files[0];
    setNewDoc(selectedFile);
  };

  const handleUploadFile = async () => {
    if (newDoc) {
      try {
        const storageRef = ref(
          getStorage(),
          `/Clients/DHL/ImportantDocs/${newDoc.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, newDoc);
        await uploadTask;

        let url = await getDownloadURL(storageRef);

        let tFileDetails = [...fileDetails];
        tFileDetails.push({
          Name: newDoc.name,
          Url: url,
        });

        setFileDetails(tFileDetails);
        showMessage({
          text: `Se subió correctamente el documento`,
          severity: "success",
        });
      } catch (error) {
        showMessage({
          text: `No se subió correctamente el documento`,
          severity: "error",
        });
      }
    }
  };

  const deleteFile = () => {
    if (!currentDoc) {
      showMessage({
        text: `Seleccionar document0 a eliminar`,
        severity: "info",
      });
    }
    if (currentDoc) {
      const filePath = `/Clients/DHL/ImportantDocs/${currentDoc.Name}`;
      const fileRef = ref(storage, filePath);
      // Delete the file
      deleteObject(fileRef)
        .then(() => {
          const docIndex = fileDetails.findIndex(
            (doc) => doc.Name == currentDoc.Name
          );
          const tFileDetails = [...fileDetails];
          tFileDetails.splice(docIndex, 1);
          setFileDetails(tFileDetails);

          showMessage({
            text: `Se eliminó correctamente el documento`,
            severity: "success",
          });
        })
        .catch((error) => {
          showMessage({
            text: `No se eliminó correctamente el documento`,
            severity: "error",
          });
        });
    }
  };

  useEffect(() => {
    const folderPath = "/Clients/DHL/ImportantDocs";

    const folderRef = ref(storage, folderPath);

    listAll(folderRef)
      .then((res) => {
        const filePromises = res.items.map(async (item) => {
          const downloadUrl = await getDownloadURL(item);
          const metadata = await getMetadata(item);
          return { Name: metadata.name, Url: downloadUrl };
        });
        return Promise.all(filePromises);
      })
      .then((fileInfoArray) => {
        setFileDetails(fileInfoArray);
      })
      .catch((error) => {
        console.error("Error listing files:", error);
      });
  }, []);

  return (
    <>
      <Grid container>
        {(state.user.Data.isAdmin || state.user.Data.isTester) && (
          <Grid container>
            <Grid item xs={10.4}>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUpload />}
              >
                Seleccionar documento
                <input
                  accept="application/pdf"
                  type="file"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>

              {newDoc && (
                <Button onClick={handleUploadFile}> Guardar documento</Button>
              )}
            </Grid>

            <Grid item xs={1.6}>
              <DeleteDocumentDialog deleteFile={deleteFile} />
            </Grid>
          </Grid>
        )}

        <Grid item xs={1.5}>
          <List>
            {fileDetails.map((fileDetail) => {
              return (
                <Grid item xs={12}>
                  <DocumentCard
                    setCurrentDoc={setCurrentDoc}
                    fileDetail={fileDetail}
                    setPreview={setPreview}
                  />
                </Grid>
              );
            })}
          </List>
        </Grid>
        {!preview && (
          <Grid
            container
            justifyContent={"center"}
            alignContent={"center"}
            sx={{ height: "100vh" }}
          >
            {" "}
            <Typography fontWeight={"bold"}>
              Selecciona un documento de la lista
            </Typography>
          </Grid>
        )}

        <Grid item xs={10.5}>
          {preview}
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentsDHL;
