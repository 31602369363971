import { lighten, Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

const height = "800px";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			animation: "fadeInRight .4s",
			maxHeight: height,
		},
		list: {
			height: height,
			overflowY: 'auto',
			overflowX: 'hidden'
		},
		pdf: {
			height: height,
			pointerEvents: "none",
			"& iframe": {
				pointerEvents: "all",
				width: "100%",
				height: "100%",
				boxShadow: "0 0 16px #D8D8D8",
			},
			paddingBottom: "30px"
		},
		selectedMaterial: {
			// backgroundColor: lighten(theme.palette.primary.main, 0.1),
			transform: "translateX(5px) scale(1.02)",
			boxShadow: "-6px 0 4px -1px #c0c0c0",
			zIndex: 2,
			// "&:hover": {
			// 	backgroundColor: lighten(theme.palette.primary.main, 0.1),
			// },
			paddingRight: 0
		},
		unselectedMaterial: {
			backgroundColor: theme.palette.grey[200],
			transition: ".2s",
			"&:hover": {
				transform: "translateX(5px) scale(1.02)",
				zIndex: 2,
				boxShadow: "-6px 0 4px -1px #c0c0c0",
			},
		},
		progress: {
			margin: "auto",
		},
		blockDivider: {
			backgroundColor: theme.palette.grey[300],
			padding: "8px 4px",
			fontWeight: "500",
			textAlign: "center",
		},
	})
);

export default useStyles;
