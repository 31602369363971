import DHLPublicRouter from './DHL';
import DefaultPublicRouter from './Default';

/**
 * Devuelve el router correspondiente a la instancia que se le pide
 * @param instance Instancia (cliente)
 * @returns El router de la instancia correspondiente
 */
export const GetInstanceRouter = (instance: string) => {
    switch (instance) {
        case 'DHL':
            return <DHLPublicRouter />
        default:
            return <DefaultPublicRouter />
    }
}