import { SvgIcon } from "@mui/material";

const home = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M25.5015,12.46a2.4145,2.4145,0,0,0-3.003,0L8,23.9058V44H28V33h6V44h6V23.9058ZM22,33H14V25h8ZM43,19l-2.87,2.46L26.7407,10.89a4.4087,4.4087,0,0,0-5.4814,0L7.641,21.6411,5,19,21.8787,5.6748a3.4235,3.4235,0,0,1,4.2426,0Z"
      />
    </SvgIcon>
  );
};
const calendar = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M24.0344,32.04h-.7126V28.96h.7126q1.1874,0,1.1872,1.3789v.3219Q25.2216,32.04,24.0344,32.04Zm5.1178-3.0261h-.1529l-.5518,2h1.2564ZM36,6V4H32V6H16V4H12V6H8v9H40V6ZM18.94,32.1471a.976.976,0,0,0,.8393-.3792,1.8367,1.8367,0,0,0,.28-1.1v-.3369a1.837,1.837,0,0,0-.28-1.0995,1.118,1.118,0,0,0-1.6782,0,1.8377,1.8377,0,0,0-.28,1.0995v.3369a1.8374,1.8374,0,0,0,.28,1.1A.9757.9757,0,0,0,18.94,32.1471ZM8,17H40V44H8ZM31.2358,27.9717l1.7235,3.1105V33.028h1.1648V31.0667l1.716-3.095H34.645l-.9806,1.9613H33.511l-.98-1.9613ZM26.7469,33.028h1.1489l.2987-1.08h1.7624l.2988,1.08h1.21l-1.4935-5.0563H28.2408Zm-4.582,0h1.87a2.3649,2.3649,0,0,0,1.7352-.6013,2.31,2.31,0,0,0,.6088-1.72v-.4136a2.311,2.311,0,0,0-.6088-1.72,2.3653,2.3653,0,0,0-1.7352-.6014h-1.87Zm-5.5007-2.3136a2.537,2.537,0,0,0,.5938,1.7813,2.1674,2.1674,0,0,0,1.6815.6474,2.1684,2.1684,0,0,0,1.6819-.6474,2.537,2.537,0,0,0,.5939-1.7813v-.4291a2.5371,2.5371,0,0,0-.5939-1.7812,2.1687,2.1687,0,0,0-1.6819-.6472,2.1676,2.1676,0,0,0-1.6815.6472,2.537,2.537,0,0,0-.5938,1.7812ZM12.16,28.9677h1.4865v4.06h1.1568v-4.06h1.486v-.996H12.16Z"
      />
    </SvgIcon>
  );
};
const summary = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M11.9966,10a1,1,0,0,1,0-2h25V6H10.9926A1.9926,1.9926,0,0,0,9,7.9926V40.0074A1.9927,1.9927,0,0,0,10.9927,42H40V10ZM27.7924,35.0081l4.997-6.955H28.0628V25.8585h7.799v1.992l-4.997,6.9549h5.1318V37H27.7924ZM16.89,14.8488h3.8151L23.9966,25.99H21.3294l-.6585-2.38H16.7884L16.13,25.99h-2.532ZM20.1138,21.55H17.3455l1.2152-4.4058h.3379Z"
      />
    </SvgIcon>
  );
};
const exercises = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M10.5649,27.5355l9.9,9.9L19.05,38.8492l-9.8994-9.9ZM40.9706,7.0294,38.98,18.3118A2.3787,2.3787,0,0,1,38.319,19.58L28.2426,29.657l.6063,6.6684a2.379,2.379,0,0,1-.687,1.8975l-2.0406,2.04L7.7366,21.8787l2.0406-2.0406a2.3788,2.3788,0,0,1,1.8974-.687l6.6685.6062L28.42,9.6809a2.3781,2.3781,0,0,1,1.2686-.66Zm-8.4853,8.4852a3,3,0,1,0,0,4.2427A3,3,0,0,0,32.4853,15.5146Zm-21.92,17.6778L9.1508,31.7782,5.6152,35.3137l1.4142,1.4142Zm.7072,7.7782,1.4142,1.4142,3.5355-3.5356-1.4142-1.4141Zm.7071-6.3639L6.3223,40.2634l1.4143,1.4143,5.6568-5.6569Z"
      />
    </SvgIcon>
  );
};
const activities = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M45,24a4,4,0,0,1-4,4H40v7H23V30a6,6,0,0,0,0-12V13h4V11a4,4,0,0,1,8,0v2h5v7h1A4,4,0,0,1,45,24ZM21,28h2a4,4,0,0,0,0-8H21V13H4V35H9v3a4,4,0,0,0,8,0V35h4Z"
      />
    </SvgIcon>
  );
};
const reta = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M42,21v2H37V21ZM11,21H6v2h5ZM25,4H23V9h2Zm7.4854,27.8994,3.5356,3.5352.737-.737.6771-.6771-3.5357-3.5351ZM15.5146,12.1006,11.979,8.5654l-.6766.6766-.7375.7375,3.5357,3.5351Zm-4.95,21.92.6888.6887.7253.7254,3.5356-3.5352-1.414-1.414ZM37.4351,9.98,36.73,9.2743l-.7089-.7089-3.5356,3.5352,1.414,1.414ZM19,42.0014A1.9986,1.9986,0,0,0,20.9986,44h6.0028A1.9986,1.9986,0,0,0,29,42.0014V40H19ZM24,11a11,11,0,0,0-6.1188,20.14A4.3361,4.3361,0,0,1,19,34.0264V38H29V34.0251a4.2991,4.2991,0,0,1,1.1534-2.9061A11.0007,11.0007,0,0,0,24,11Z"
      />
    </SvgIcon>
  );
};
const announcements = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M35,32H13V19A11.0046,11.0046,0,0,1,21,8.4142V7a3,3,0,0,1,6,0V8.4142A11.0046,11.0046,0,0,1,35,19Zm3,4.0061A2.0061,2.0061,0,0,0,35.9939,34H12.0061A2.0061,2.0061,0,0,0,10,36.0061V38H38ZM24,44a4,4,0,0,0,4-4H20A4,4,0,0,0,24,44Z"
      />
    </SvgIcon>
  );
};
const catalog = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M29.5,11H42v5H17V11ZM17,27H42V22H17Zm0,11H42V33H17ZM9.5,10A3.5,3.5,0,1,0,13,13.5,3.5,3.5,0,0,0,9.5,10Zm0,11A3.5,3.5,0,1,0,13,24.5,3.5,3.5,0,0,0,9.5,21Zm0,11A3.5,3.5,0,1,0,13,35.5,3.5,3.5,0,0,0,9.5,32Z"
      />
    </SvgIcon>
  );
};

const areuready = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M23.99,42c-4.61,0-9.22-1.75-12.73-5.26-7.02-7.02-7.02-18.44,0-25.46,3.39-3.39,7.92-5.27,12.74-5.28v4c-3.75,0-7.27,1.47-9.91,4.11-5.46,5.46-5.46,14.34,0,19.8,5.46,5.46,14.34,5.46,19.8,0,2.64-2.64,4.11-6.17,4.11-9.91h4c0,4.81-1.88,9.34-5.28,12.74-3.51,3.51-8.12,5.26-12.73,5.26Zm7.08-10.92c1.89-1.89,2.93-4.4,2.93-7.08h-4c0,1.6-.62,3.11-1.75,4.25-2.34,2.34-6.15,2.34-8.49,0-1.13-1.13-1.76-2.64-1.76-4.24s.62-3.11,1.76-4.24c1.13-1.13,2.63-1.76,4.24-1.76v-4c-2.67,0-5.19,1.05-7.07,2.93-1.89,1.89-2.93,4.4-2.93,7.07s1.04,5.18,2.93,7.07c1.95,1.95,4.51,2.92,7.07,2.92,2.56,0,5.12-.97,7.07-2.92Zm12.93-20.08l-4.81-2.2-2.19-4.8-7,7v4.17l-7.41,7.41c-.78,.78-.78,2.05,0,2.83,.39,.39,.9,.59,1.41,.59s1.02-.2,1.41-.59l7.41-7.41h4.17l7-7Z"
      />
    </SvgIcon>
  );
};

const close = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M26.8281,24l14.086,14.0859-2.8282,2.8282L24,26.8281,9.9141,40.9141,7.0859,38.0859,21.1719,24,7.0859,9.9141,9.9141,7.0859,24,21.1719,38.0859,7.0859l2.8282,2.8282Z"
      />
    </SvgIcon>
  );
};

const check = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M19.03,36.7988,5.6162,23.9438l2.7676-2.8876L18.97,31.2012,39.5859,10.5859l2.8282,2.8282Z"
      />
    </SvgIcon>
  );
};

const selectPartic = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M28,9c0,2.2091-1.7909,4-4,4s-4-1.7909-4-4,1.7909-4,4-4,4,1.7909,4,4Zm-6.9991,6c-1.0854,0-1.9379,.9265-1.8438,2.004l1.8322,20.996h6.0213l1.8322-20.996c.094-1.0775-.7584-2.004-1.8438-2.004h-5.9982Zm16.9991,0c-1.6569,0-3,1.3431-3,3s1.3431,3,3,3,3-1.3431,3-3-1.3431-3-3-3Zm-1.9967,8c-1.0789,0-1.9298,.9189-1.8478,1.9957l1.4466,19.0043h4.7958l1.4466-19.0043c.082-1.0768-.7689-1.9957-1.8478-1.9957h-3.9935ZM10,15c-1.6569,0-3,1.3431-3,3s1.3431,3,3,3,3-1.3431,3-3-1.3431-3-3-3Zm-1.9967,8c-1.0789,0-1.9298,.9189-1.8478,1.9957l1.4466,19.0043h4.7958l1.4466-19.0043c.082-1.0768-.7689-1.9957-1.8478-1.9957h-3.9935Zm22.9967,17h-14v4h14v-4Z"
      />
    </SvgIcon>
  );
};

const alert = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M24,4A20,20,0,1,0,44,24,20,20,0,0,0,24,4Zm2.3735,9.6592L25.729,26.7285H22.271l-.6152-13.0693Zm-.4248,19.1064a3.1334,3.1334,0,0,1-3.8828,0,2.4642,2.4642,0,0,1,0-3.6191,3.0814,3.0814,0,0,1,3.8828,0,2.4969,2.4969,0,0,1,0,3.6191Z"
      />
    </SvgIcon>
  );
};

const admin = function () {
  return (
    <SvgIcon>
      <path
        transform="scale(.5)"
        d="M44,34.3333V29.6667l-3.6054-.7211L39.51,26.81,41.5494,23.75l-3.3-3.3L35.19,22.49l-2.1359-.8846L32.3333,18H27.6667l-.7211,3.6054L24.81,22.49,21.75,20.4506l-3.3,3.3L20.49,26.81l-.8846,2.1359L16,29.6667v4.6666l3.6054.7211L20.49,37.19,18.4506,40.25l3.3,3.3L24.81,41.51l2.1359.8846L27.6667,46h4.6666l.7211-3.6054L35.19,41.51,38.25,43.5494l3.3-3.3L39.51,37.19l.8846-2.1359ZM30,36.999A4.999,4.999,0,1,1,34.999,32,4.9991,4.9991,0,0,1,30,36.999ZM27,13V11l-2.188-.7293a7.953,7.953,0,0,0-1.0654-2.5715L24.7782,5.636,23.364,4.2218,21.3008,5.2534A7.953,7.953,0,0,0,18.7293,4.188L18,2H16l-.7293,2.188a7.953,7.953,0,0,0-2.5715,1.0654L10.636,4.2218,9.2218,5.636l1.0316,2.0632A7.953,7.953,0,0,0,9.188,10.2707L7,11v2l2.188.7293a7.953,7.953,0,0,0,1.0654,2.5715L9.2218,18.364l1.4142,1.4141,2.0632-1.0315a7.9533,7.9533,0,0,0,2.5715,1.0654L16,22h2l.7293-2.188a7.9533,7.9533,0,0,0,2.5715-1.0654l2.0632,1.0315,1.4142-1.4141-1.0316-2.0632a7.953,7.953,0,0,0,1.0654-2.5715ZM17,16a4,4,0,1,1,4-4A4,4,0,0,1,17,16Z"
      />
    </SvgIcon>
  );
};

export {
  home,
  calendar,
  summary,
  exercises,
  activities,
  reta,
  announcements,
  catalog,
  areuready,
  check,
  close,
  selectPartic,
  alert,
  admin,
};
