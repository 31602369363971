import React, { Suspense, useEffect } from "react";
import { useAppContext } from "../../auth/appContext";
import withAuth from "../../auth/withAuth";
import * as AdminPageHub from "./Instances";

const AdminPage: React.FC = () => {
  const { AppSettings } = useAppContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  switch (AppSettings.Name) {
    case "DHL":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <AdminPageHub.DHL />
        </Suspense>
      );
    default:
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <AdminPageHub.Default />
        </Suspense>
      );
  }
};

export default withAuth(AdminPage);
