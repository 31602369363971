import { useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { Contact } from '../../../../../interfaces';
import EditAnnonuncementsView from './view';

interface Props {
    setEdit: (e: boolean) => void;
    fade: boolean;
    instructors: Contact[];
}

const EditAnnonuncements: React.FC<Props> = ({ setEdit, fade, instructors }) => {
    const theme = useTheme();
    const [content, setContent] = useState<string>('studyTips');
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setContent(newValue);
    };

    return (
        <EditAnnonuncementsView
            content={content}
            handleChange={handleChange}
            isMobile={isMobile}
            setEdit={setEdit}
            fade={fade}
            instructors={instructors}
        />
    )


}
export default EditAnnonuncements;