import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useAppContext } from "../../../../auth/appContext";
import { AssignmentV2 } from "../../../../interfaces/assignment";
import { MaterialItem } from "../../../../interfaces/course";
import PresentingTest from "../../../Study/Instances/Default/PresentingTest";
import useStyles from "./style";

interface Props {
  mixTest: MaterialItem | undefined;
  assignment: AssignmentV2;
  back: () => void;
}

const AreYouReadyView: React.FC<Props> = ({ mixTest, assignment, back }) => {
  const classes = useStyles();
  const { AppSettings } = useAppContext();

  if (!mixTest || mixTest.Questions?.length === 0) {
    return (
      <div className={classes.root}>
        <Typography fontWeight="bold" variant="h5">
          Sección no disponible por el momento
        </Typography>
        <Typography variant="body1">
          Regresa cuando hayas terminado de estudiar todos los módulos
        </Typography>
      </div>
    );
  } else {
    return (
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Button
            variant="contained"
            fullWidth
            onClick={back}
            color="secondary"
          >
            Reiniciar
          </Button>
        </Grid>
        <Grid item xs={10}>
          <PresentingTest
            key={new Date().toString()}
            assignment={undefined}
            topicIndex={0}
            moduleIndex={0}
            material={mixTest}
            minScore={AppSettings.Data?.minTestScore ?? 70}
            updateProgress={() => {}}
            back={back}
            handleReset={() => {}}
            examen={false}
          />
        </Grid>
      </Grid>
    );
  }
};

export default AreYouReadyView;
