import { firestore, storage } from "../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { collection, getDocs, query, updateDoc, where } from "firebase/firestore";
import { AssignmentV2 } from "../interfaces/assignment";

export const padStartZero = (num: number, maxLength: number) => `${num}`.padStart(maxLength, "0");
export const padEndZero = (num: number, maxLength: number) => `${num}`.padEnd(maxLength, "0");

export const shuffle = (array: any[]) => {
	let currentIndex = array.length,
		randomIndex;

	// While there remain elements to shuffle...
	while (currentIndex !== 0) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
	}

	return array;
};

export function findLastIndex<T>(
	array: Array<T>,
	predicate: (value: T, index: number, obj: T[]) => boolean
): number {
	let l = array.length;
	while (l--) {
		if (predicate(array[l], l, array)) return l;
	}
	return -1;
}

export function stringToColor(string: string) {
	let hash = 0;
	let i;
	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}
	let color = "#";
	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.substr(-2);
	}
	/* eslint-enable no-bitwise */
	return color;
}

export function sumArrays(...arrays: any[]) {
	const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
	const result = Array.from({ length: n });
	return result.map((_, i) => arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0));
}

/**
 * Es un Helper (nuestro) para obtener la URL de un archivo
 * @param file PATH al archivo de Firebase Storage que queremos obtener
 * @returns URL del archivo
 * @example getDownloadUrl('Clients/Softtek/Courses/courseID/file.pdf')
 */
export const getDownloadUrl = async (file: string) => {
	const fileRef = ref(storage, file);
	//Usar la función de firebase storage (del mismo nombre... casi)
	return await getDownloadURL(fileRef);
};


export const toTitleCase = (str: string) => {
	return str.replace(
		/\w\S*/g,
		function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}
	);
}

export const dynamicSort = (property: string) => {
	var sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return function (a: any, b: any) {
		/* next line works with strings and numbers, 
		 * and you may want to customize it to your needs
		 */
		var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
		return result * sortOrder;
	}
}

export const saveAttemptFB = async (instance: string, updatedAssignment: AssignmentV2) => {
	const assignmentsRef = collection(
		firestore,
		`Instances/${instance}/Assignments`
	);
	const queryDef = query(
		assignmentsRef,
		where("User", "==", updatedAssignment.User),
		where("Course", "==", updatedAssignment.Course)
	);
	const assignmentDoc = (await getDocs(queryDef)).docs[0];

	//Actualización en DB
	await updateDoc(assignmentDoc.ref, updatedAssignment as any);
}