import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, Grid, Input, TextField, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { FormEvent, useEffect, useState } from "react";
import { Contact } from "../../../../../../interfaces";
import useStyles from "../../style";
import { Download, UploadFile } from "@mui/icons-material";
import { validation } from "../../../../../../interfaces/validation";
import * as XLSX from 'xlsx'
import { isEmpty } from "lodash";
import { useShowMessage } from "../../../../../../providers/MessageProvider/MessageProvider";
import { addDoc, collection, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../../../firebase";
import { useAppContext } from "../../../../../../auth/appContext";
import { validarCargaInstructores } from "./validateFile";
import { instructorClass } from "../../../../../../interfaces/classes";
import ValidationExcel from "../../CertificationsTable/Dialogs/excel";

interface Props {
    columns: GridColDef[];
    instructors: Contact[];
    pageSize: number;
    setPageSize: (e: number) => void;
    instructor: Contact | undefined;
    setInstructor: (e: Contact | undefined) => void;
    downloadTemplate: () => void;
    openDelete: string;
    setOpenDelete: (e: string) => void;
}

const EditInstructorsView: React.FC<Props> = ({
    columns,
    pageSize,
    setPageSize,
    instructor,
    setInstructor,
    downloadTemplate,
    instructors,
    openDelete,
    setOpenDelete
}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState<boolean>(false);
    const [file, setFile] = useState<File | undefined>();
    const [canSave, setCanSave] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);
    const [validationResults, setValidationResults] = useState<validation>({});
    const [openResults, setOpenResults] = useState<boolean>(false);
    const { showMessage } = useShowMessage();
    const AppSettings = useAppContext();

    const handleFile = (e: FormEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files || [];
        if (files.length > 0) {
            const files = (e.target as HTMLInputElement).files || [];
            const file = files[0];
            if (!file.name.endsWith('xlsx')) {
                showMessage({
                    severity: 'error',
                    text: 'El tipo de archivo no es compatible, por favor utilice uno con extensión .xlsx'
                })
                return;
            } else {
                setFile(file);
            }
        }
    }

    const handleSaveXLSX = async () => {
        if (file) {
            setSaving(true);
            const fileData = await file.arrayBuffer();
            const workbook = XLSX.read(fileData);
            const sheets = workbook.Sheets;
            const nameSheets = Object.keys(sheets);

            let resultsValidation: validation = {};
            resultsValidation["instructores"] = validarCargaInstructores(sheets, nameSheets);

            if (!isEmpty(resultsValidation["instructores"])) {
                setValidationResults(resultsValidation);
                setOpenResults(true);
                setSaving(false);
                setFile(undefined);
            } else {
                const data = XLSX.utils.sheet_to_json(sheets[nameSheets[0]], { header: 1, }) as any[][];
                if (!isEmpty(resultsValidation["instructores"])) {
                    setValidationResults(resultsValidation);
                    setOpenResults(true);
                    setFile(undefined);
                } else {
                    await saveExceltoDB(data)
                }
            }
            setSaving(false)
        }
    }

    /**
     * Guardar instructores del archivo excel a firestore
     * @param instructores instructores de excel
     */
    const saveExceltoDB = async (instructores: any[][]) => {
        const instructorsRef = collection(firestore, `Instances/${AppSettings.AppSettings.Name}/Instructors`);
        let prioridadTemporal = instructores.length;
        for (let row = 1; row < instructores.length; row++) {
            const instructor: Contact = {
                Name: instructores[row][0],
                Region: instructores[row][1],
                Email: instructores[row][2] ?? '',
                ID: '',
                Priority: prioridadTemporal,
            }
            const ref = await addDoc(instructorsRef, instructor);
            await updateDoc(ref, {
                ID: ref.id,
            });
            prioridadTemporal ++;
        }
        showMessage({
            severity: 'success',
            text: 'Instructores agregados correctamente',
        });
        setFile(undefined);
    }

    /**
    * Verificar que los campos esenciales no esten vacíos
    */
    useEffect(() => {
        if (instructor) {
            if (instructor.Name.toString().trim() === "" ||
                instructor.Region.toString().trim() === "") {
                setCanSave(false)
            } else setCanSave(true);
        }
    }, [instructor]);

    /**
     * Para cancelar y limpiar todos los campos
     */
    const handleCancel = () => {
        setInstructor(undefined);
        openDelete && setOpenDelete('');
    }

    /**
     * Guardar cambios en bd
     */
    const handleSave = async () => {
        if (instructor?.ID !== '') {
            const instructorRef = doc(firestore, `Instances/${AppSettings.AppSettings.Name}/Instructors/${instructor?.ID}`);
            await updateDoc(instructorRef, instructor as any)
            handleCancel();
        } else {
            const instructorRef = await addDoc(collection(firestore, `Instances/${AppSettings.AppSettings.Name}/Instructors`), instructor);
            await updateDoc(instructorRef, { ID: instructorRef.id })
            handleCancel();
        }
    }

    /**
     * Eliminar instructor de bd
     */
    const handleDelete = async () => {
        const refStudyTip = doc(firestore, `Instances/${AppSettings.AppSettings.Name}/Instructors/${openDelete}`)
        await deleteDoc(refStudyTip)
        handleCancel();
    }

    return (
        <Grid container spacing={2}>
            {/* BOTONES SUPERIORES TABLA */}
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container justifyContent='flex-end'>
                            <Grid item xs={12} lg={2} md={3}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={downloadTemplate}
                                    fullWidth
                                >
                                    Descargar plantilla <Download />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item xs={2}>
                                <Button
                                    variant='contained'
                                    onClick={() =>
                                        setInstructor(new instructorClass(instructors.length + 1))
                                    }
                                >
                                    Agregar nuevo instructor
                                </Button>
                            </Grid>
                            <Grid item xs={7}>
                                <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                                    <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                                        <Typography sx={{ flexShrink: 0 }}>
                                            Cargar archivo Excel
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Grid container justifyContent='center' alignItems='center'
                                                    sx={{
                                                        padding: 1,
                                                        borderStyle: 'dashed',
                                                        borderWidth: '0.3vh',
                                                        bgcolor: '#E5E8E8'
                                                    }}
                                                >
                                                    <Grid item xs={12} justifyContent='center'>
                                                        <UploadFile
                                                            sx={{
                                                                color: 'gray',
                                                                width: '100%',
                                                                height: '6vh'
                                                            }} />
                                                    </Grid>
                                                    <Grid item xs={12} container justifyContent='center'>
                                                        <Input
                                                            type='file'
                                                            size='small'
                                                            disableUnderline
                                                            onChangeCapture={handleFile}
                                                            key={file?.name}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container justifyContent='center' alignItems='center' sx={{ marginTop: 1 }}>
                                                <Button
                                                    variant="contained"
                                                    color='secondary'
                                                    disabled={!file || saving}
                                                    onClick={handleSaveXLSX}
                                                >
                                                    Cargar archivo
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <DataGrid
                    rows={instructors}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    disableSelectionOnClick
                    disableColumnSelector
                    pagination
                    getRowId={(row) => row.ID}
                    componentsProps={{
                        pagination: {
                            labelRowsPerPage: "Filas por página"
                        }
                    }}
                    autoHeight
                    className={classes.table}
                />
            </Grid>

            {/* DIALOGOS DE INSTRUCTOR */}
            {instructor &&
                <Dialog
                    open={instructor !== undefined}
                    onClose={() =>
                        handleCancel
                    }
                >
                    <DialogContent>
                        <Grid container sx={{ p: 2 }}>
                            <Grid item xs={12} sx={{ py: 2 }}>
                                <Typography
                                    align='center'
                                    sx={{
                                        fontWeight: "bold"
                                    }}
                                    variant="h5"
                                >
                                    {instructor?.ID !== "" ? `Editar` : 'Agregar nuevo'} instructor
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ my: 2 }}>
                                <TextField
                                    label="Nombre"
                                    variant="outlined"
                                    fullWidth
                                    value={instructor.Name}
                                    onChange={(e) => {
                                        setInstructor({
                                            ...instructor,
                                            Name: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ my: 2 }}>
                                <TextField
                                    label="Región"
                                    variant="outlined"
                                    rows={3}
                                    fullWidth
                                    value={instructor.Region}
                                    onChange={(e) => {
                                        setInstructor({
                                            ...instructor,
                                            Region: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ my: 2 }}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    fullWidth
                                    value={instructor.Email}
                                    onChange={(e) => {
                                        setInstructor({
                                            ...instructor,
                                            Email: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ my: 2 }}>
                                <TextField
                                    label="Numero Teléfonico"
                                    variant="outlined"
                                    fullWidth
                                    value={instructor.Tel}
                                    onChange={(e) => {
                                        setInstructor({
                                            ...instructor,
                                            Tel: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={handleCancel}
                        >
                            Cancelar
                        </Button>
                        <Button
                            disabled={!canSave}
                            variant="outlined"
                            color="secondary"
                            sx={{ border: "1.5px solid" }}
                            onClick={handleSave}
                        >
                            Guardar
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            <Dialog
                open={openDelete !== ''}
            >
                <DialogContent>
                    <Grid container sx={{ p: 2 }}>
                        <Grid item xs={12}>
                            <Typography>
                                ¿Esta seguro que desea eliminar el instructor seleccionado?
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={handleCancel}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        sx={{ border: "1.5px solid" }}
                        onClick={handleDelete}
                    >
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            <ValidationExcel
                openValidation={openResults}
                setOpenValidation={setOpenResults}
                resultsValidation={validationResults}
            />

        </Grid>
    )
}
export default EditInstructorsView;