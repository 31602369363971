import {
    Grid,
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Typography,
    Input,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    styled,
    alpha,
} from "@mui/material";
import { UploadFile, Download, Add, Delete } from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import { DataGrid, GridColDef, GridExpandMoreIcon, esES, gridClasses } from "@mui/x-data-grid";
import { addDoc, collection, getDocs, query, Timestamp, updateDoc, where } from "firebase/firestore";
import { Certification } from "../../../../../interfaces/certifications";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isEmpty } from "lodash";
import { useDataContext } from "../../../../../auth/dataContext";
import * as XLSX from 'xlsx';
import { useState } from "react";
import { useAppContext } from "../../../../../auth/appContext";
import examplePNG from '../../../../../assets/images/example.png';
import { validation } from "../../../../../interfaces/validation";
import { validarCargaCertificaciones } from "./validateFile";
import ValidationExcel from "./Dialogs/excel";
import DeleteCertificationDialog from "./Dialogs/deleteCertification";
import { firestore } from "../../../../../firebase";
import { useShowMessage } from "../../../../../providers/MessageProvider/MessageProvider";
import { CustomCertsTableToolbar } from "../../../../../utils/CertificationsTableToolbar";

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
    },
}));

interface Props {
    search: string;
    setSearch: (e: string) => void;
    certDates: Certification[];
    handleCancel: () => void;
    handleSave: () => void;
    openDelete: Certification | undefined;
    setOpenDelete: (e: Certification | undefined) => void;
    handleDelete: () => void;
    showButton?: boolean;
    canSave: boolean;
    certification: Certification | undefined;
    setCertification: (e: Certification | undefined) => void;
    downloadTemplate: () => void;
    gridCols: GridColDef[];
}

const CertificationsTableView: React.FC<Props> = ({
    search,
    setSearch,
    certDates,
    handleCancel,
    handleSave,
    openDelete,
    setOpenDelete,
    handleDelete,
    showButton,
    canSave,
    certification,
    setCertification,
    downloadTemplate,
    gridCols
}) => {
    const { state } = useDataContext();
    const { AppSettings } = useAppContext();
    const { showMessage } = useShowMessage();
    const [enable, setEnable] = useState<boolean>(false);
    const [openHelp, setOpenHelp] = useState<boolean>(false);
    const [file, setFile] = useState<File>();
    const [saving, setSaving] = useState<boolean>(false);
    const [validationResults, setValidationResults] = useState<validation>({});
    const [openResults, setOpenResults] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(5);

    /**
     * Listener del archivo que valida la extensión antes de habilitar botón de carga
     * @param e Archivo
     */
    const handleFile = (e: React.FormEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files || [];
        if (files.length > 0) {
            const files = (e.target as HTMLInputElement).files || [];
            const file = files[0];
            if (!file.name.endsWith('xlsx')) {
                showMessage({
                    text: "Este archivo no es compatible, por favor verifique que la extensión sea .xlsx",
                    severity: 'error',
                });
                setEnable(false);
                return;
            } else {
                setFile(file);
                setEnable(true)
            }
        } else {
            setFile(undefined)
            setEnable(false);
        }
    }

    /**
     * Verifica en las filas existentes de bd que no exista el mismo SVC
     * @param SVC Service center
     * @returns existe o no existe
     */
    const verificarSVC = (SVC: string): boolean => {
        let existeDato = false;
        certDates.map(
            // eslint-disable-next-line array-callback-return
            (item) => {
                if (item.SVC.toLocaleUpperCase() === SVC.toLocaleUpperCase()) {
                    existeDato = true;
                }
            })
        return existeDato;
    }

    const verifyFile = async () => {
        if (file) {
            setSaving(true);
            const fileData = await file.arrayBuffer();
            const workbook = XLSX.read(fileData, { dateNF: 'mm-dd-yyyy hh:mm', cellDates: true });
            const sheets = workbook.Sheets;
            const nameSheets = Object.keys(sheets);

            let resultsValidation: validation = {};
            resultsValidation["Fechas_Certificacion"] = validarCargaCertificaciones(sheets, nameSheets);

            if (!isEmpty(resultsValidation["Fechas_Certificacion"])) {
                setValidationResults(resultsValidation);
                setOpenResults(true);
                setSaving(false);
            } else {
                const data = XLSX.utils.sheet_to_json(sheets[nameSheets[0]], { header: 1, }) as any[][];

                getFechasCertificacion(data).then((fechas) => {
                    setSaving(false);
                    fechas.forEach((fecha, index) => {
                        if (verificarSVC(fecha.SVC)) {
                            resultsValidation["Fechas_Certificacion"]["Fechas_Certificacion"] = {
                                [`A${index + 1}`]: {
                                    header: "SVC",
                                    rules: "El SVC no debe estar repetido",
                                    details: "El SVC esta repetido con alguno de los registrados actualmente o dentro de la hoja",
                                    value: data[index + 1][0]
                                }
                            }
                        }
                    })

                    if (!isEmpty(resultsValidation["Fechas_Certificacion"])) {
                        setValidationResults(resultsValidation);
                        setOpenResults(true);
                    } else {
                        fechas.forEach((fecha) => {
                            saveExceltoDB(fecha)
                        })
                    }
                })
            }
            setSaving(false)
        }
    }

    /**
     * Guardar en firestore todas las fechas de certificación de archivo excel
     * @param FechaCertif fechas de certificacion excel
     */
    const saveExceltoDB = async (FechaCertif: Certification) => {
        const q = query(collection(firestore, `Instances/${AppSettings.Name}/CertificationDates`), where('SVC', '==', FechaCertif.SVC));
        const snap = await getDocs(q)
        if (snap.empty) {
            const newCertifRef = await addDoc(collection(firestore, `Instances/${AppSettings.Name}/CertificationDates`), FechaCertif);
            await updateDoc(newCertifRef, { ID: newCertifRef.id })
            FechaCertif.ID = newCertifRef.id;
            handleCancel();
        }
    }

    const getFechasCertificacion = async (data: any[][]) => {
        let certifArray: Certification[] = [];
        for (let row = 1; row < data.length; row++) {
            let FechaCertif: Certification = {
                ID: '',
                SVC: data[row][0],
                Region: data[row][1],
                DateTime: data[row][2],
                Place: data[row][3],
                Leader: data[row][4],
                Applicators: [''],
            }
            if (data[row][5]) {
                FechaCertif.Applicators = data[row][5].split(',')
            }
            certifArray.push(FechaCertif);
        }
        return certifArray;
    }

    return (
        <Grid container spacing={2}>
            {(state.user.Data?.isAdmin || state.user.Data?.isTester) && showButton &&
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container justifyContent='flex-end'>
                                <Grid item xs={12} lg={2} md={3}>
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={downloadTemplate}
                                        fullWidth
                                    >
                                        Descargar plantilla <Download />
                                    </Button>

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent='space-between' alignItems='center'>
                                <Grid item xs={2}>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            setCertification({
                                                ID: '',
                                                Leader: '',
                                                Place: '',
                                                Region: '',
                                                SVC: '',
                                                DateTime: Timestamp.fromDate(new Date()),
                                                Applicators: [],
                                            })
                                        }}
                                    >
                                        Agregar nueva fecha
                                    </Button>
                                </Grid>
                                <Grid item xs={7}>
                                    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                                        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
                                            <Typography sx={{ flexShrink: 0 }}>
                                                Cargar archivo Excel
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Grid container justifyContent='center' alignItems='center'
                                                        sx={{
                                                            padding: 1,
                                                            borderStyle: 'dashed',
                                                            borderWidth: '0.3vh',
                                                            bgcolor: '#E5E8E8'
                                                        }}>
                                                        <Grid item xs={12} justifyContent='center'>
                                                            <UploadFile sx={{ color: 'gray', width: '100%', height: '6vh' }} />
                                                        </Grid>
                                                        <Grid item xs={12} container justifyContent='center'>
                                                            <Input
                                                                type='file'
                                                                size='small'
                                                                disableUnderline
                                                                onChangeCapture={handleFile}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} container justifyContent='center' alignItems='center'
                                                    sx={{
                                                        marginTop: 1
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color='secondary'
                                                        disabled={!enable || saving}
                                                        onClick={verifyFile}
                                                    >
                                                        Cargar archivo
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <StripedDataGrid
                rows={certDates}
                columns={gridCols}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    "& .MuiDataGrid-cell": {
                        lineHeight: 'unset !important',
                        maxHeight: 'none !important',
                        whiteSpace: 'normal',
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        border: "none",
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: '#000000'
                    },
                    "& .MuiDataGrid-row": {
                        maxHeight: 'none !important',
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none'
                    }
                }}
                components={{
                    Toolbar: CustomCertsTableToolbar,
                }}
                componentsProps={{
                    toolbar: {
                        search: search,
                        setSearch: setSearch,
                    }
                }}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                disableSelectionOnClick
                getRowId={(row) => row.ID}
                getRowClassName={(params) =>
                    params.row.id % 2 === 0 ? 'even' : 'odd'
                }
            />

            {certification &&
                <Dialog
                    open={certification !== undefined}
                    onClose={() => handleCancel}
                >
                    <DialogContent>
                        <Grid container sx={{ p: 2 }} spacing={2}>
                            <Grid item xs={12} sx={{ py: 2 }}>
                                <Typography align='center' sx={{ fontWeight: "bold" }} variant="h5">
                                    {certification.ID ? `Editar` : 'Agregar nueva'} fecha para certificación
                                </Typography>
                            </Grid>
                            <Grid item xs={12}
                            >
                                <TextField
                                    label="Leader"
                                    variant="outlined"
                                    fullWidth
                                    value={certification.Leader}
                                    onChange={(e) => {
                                        setCertification({
                                            ...certification,
                                            Leader: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Place"
                                    variant="outlined"
                                    rows={3}
                                    fullWidth
                                    multiline
                                    value={certification.Place}
                                    onChange={(e) => {
                                        setCertification({
                                            ...certification,
                                            Place: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Region"
                                    variant="outlined"
                                    fullWidth
                                    value={certification.Region}
                                    onChange={(e) => {
                                        setCertification({
                                            ...certification,
                                            Region: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Service center (SVC)"
                                    variant="outlined"
                                    fullWidth
                                    value={certification.SVC}
                                    onChange={(e) => {
                                        setCertification({
                                            ...certification,
                                            SVC: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DateTimePicker
                                        value={certification.DateTime?.toDate()}
                                        label="Fecha y hora"
                                        onChange={(newValue: Date | null) => {
                                            if (newValue !== null) {
                                                setCertification({
                                                    ...certification,
                                                    DateTime: Timestamp.fromDate(newValue)
                                                })
                                            }
                                        }}
                                        renderInput={(params) =>
                                            <TextField
                                                fullWidth
                                                {...params}
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems='center'>
                                    <Grid item xs={10}>
                                        <Typography
                                            variant='body2'
                                            fontWeight={600}
                                        >
                                            Aplicadores
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} container justifyContent='flex-end'>
                                        <IconButton
                                            onClick={() => {
                                                const temp = { ...certification };
                                                temp.Applicators.push("");
                                                setCertification({
                                                    ...certification,
                                                    Applicators: temp.Applicators,
                                                });
                                            }}
                                        >
                                            <Add />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {certification.Applicators.map(
                                            (applicator, key) => (
                                                <Grid container spacing={2} key={key} sx={{ my: 1 }}>
                                                    <Grid item xs={10} >
                                                        <TextField
                                                            value={applicator}
                                                            onChange={(e) => {
                                                                const updated = [
                                                                    ...certification.Applicators,
                                                                ];
                                                                updated[key] = e.target.value;
                                                                setCertification({
                                                                    ...certification,
                                                                    Applicators: updated,
                                                                });
                                                            }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <IconButton
                                                            onClick={() => {
                                                                const temp = { ...certification };
                                                                temp.Applicators.splice(key, 1);
                                                                setCertification({
                                                                    ...certification,
                                                                    Applicators: temp.Applicators,
                                                                });
                                                            }}
                                                        >
                                                            <Delete />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={handleCancel}
                        >
                            Cancelar
                        </Button>
                        <Button
                            disabled={!canSave}
                            variant="outlined"
                            color="secondary"
                            sx={{ border: "1.5px solid" }}
                            onClick={handleSave}
                        >
                            Guardar
                        </Button>
                    </DialogActions>
                </Dialog>
            }

            <Dialog
                open={openHelp}
                onClose={() => setOpenHelp(false)}
                maxWidth='lg'
            >
                <Grid container>
                    <img
                        src={examplePNG}
                        alt=''
                    />
                </Grid>
            </Dialog>
            <DeleteCertificationDialog
                handleCancel={handleCancel}
                openDelete={openDelete}
                handleDelete={handleDelete}
                setOpenDelete={setOpenDelete}
            />
            <ValidationExcel
                openValidation={openResults}
                setOpenValidation={setOpenResults}
                resultsValidation={validationResults}
            />
        </Grid >
    )
}
export default CertificationsTableView;