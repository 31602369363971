import { GridColDef } from "@mui/x-data-grid";
import { useAppContext } from "../../../../../../auth/appContext"
import { useDataContext } from "../../../../../../auth/dataContext";
import StudyTipsView from "./view";
import { Grid, IconButton, LinearProgress, Tooltip, Typography } from "@mui/material";
import { DeleteOutlined, Edit } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Timestamp, addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, updateDoc } from "firebase/firestore";
import { firestore, storage } from "../../../../../../firebase";
import { StudyTip } from "../../../../../../interfaces/course";
import { deleteObject, ref, uploadBytes } from "firebase/storage";
import { getDownloadUrl } from "../../../../../../helpers";
import { useShowMessage } from "../../../../../../providers/MessageProvider/MessageProvider";
import { findIndex, orderBy as OrderByLodash } from "lodash";

const StudyTips: React.FC = () => {
    const { AppSettings } = useAppContext();
    const { dispatch, state } = useDataContext();
    const { showMessage } = useShowMessage();
    const [studyTips, setStudyTips] = useState<StudyTip[]>()
    const [studyTip, setStudyTip] = useState<StudyTip>();
    const [file, setFile] = useState<File>();
    const [type, setType] = useState<any>();
    const [openDelete, setOpenDelete] = useState<StudyTip>();

    // COLUMNAS DATAGRID
    const columns: GridColDef[] = [
        {
            field: 'Title',
            headerName: 'Titulo',
            flex: 0.7,
            renderCell: (params: any) => params.row.Title
        },
        {
            field: 'Description',
            headerName: 'Descripción',
            flex: 0.7,
            renderCell: (params: any) => params.row.Description.length > 30 ?
                <Tooltip
                    title={params.row.Description}
                    arrow
                >
                    <Typography
                        variant="inherit"
                    >
                        {params.row.Description.slice(0, 30)}...
                    </Typography>
                </Tooltip>
                :
                params.row.Description
        },
        {
            field: 'From',
            headerName: 'Desde...',
            flex: 1,
            renderCell: (params: any) =>
                (params.row.From as Timestamp).toDate().toLocaleDateString('es-MX', {
                    hourCycle: 'h12',
                    hour: 'numeric',
                    minute: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                })
        },
        {
            field: 'id',
            headerName: '',
            align: 'center',
            flex: 0.2,
            renderCell: (params: any) =>
                <Grid container>
                    <Grid item xs={6} container justifyContent='center'>
                        <IconButton
                            color="secondary"
                            onClick={() => {
                                setStudyTip(params.row)
                            }}
                        >
                            <Edit />
                        </IconButton>
                    </Grid>
                    <Grid item xs={6} container justifyContent='center'>
                        <IconButton
                            color="secondary"
                            onClick={() => {
                                setOpenDelete(params.row)
                            }}
                        >
                            <DeleteOutlined />
                        </IconButton>
                    </Grid>
                </Grid>
        },
    ];

    // OBSERVADOR DE CAMBIOS EN COLECCIÓN DE TIPS DE ESTUDIO
    useEffect(() => {
        const queryRef = query(collection(firestore, `Instances/${AppSettings.Name}/StudyTips`), orderBy('From', 'desc'));
        const observer = onSnapshot(queryRef, snap => {
            if (!snap.empty) {
                const docs = snap.docs.map((doc) => { return doc.data() as StudyTip });
                setStudyTips(OrderByLodash(docs, 'From', 'asc'));
            } else
                setStudyTips([]);
        });
        return () => { observer() }
    }, [AppSettings.Name]);

    /**
     * GUARDAR STUDY TIP
     */
    const handleSave = async () => {
        try {
            const tempData = { ...state };

            if (studyTip?.ID) {
                const studyTipRef = doc(firestore, `Instances/${AppSettings.Name}/StudyTips/${studyTip.ID}`);

                if (studyTip?.HasImage && file) {
                    const bytes = await file.arrayBuffer();
                    // PATH GUARDADO STORAGE
                    const path = `Clients/${AppSettings.Name}/StudyTips/${studyTip.ID}/${studyTip.ID}`;
                    const fileRef = ref(storage, path);
                    // GUARDAR IMAGEN EN STORAGE
                    await uploadBytes(fileRef, bytes, type);
                    const fullPath = await getDownloadUrl(fileRef.fullPath);
                    studyTip.Img = fullPath;
                }
                await updateDoc(studyTipRef, studyTip as any);

            } else {
                const newStudyTipRef = await addDoc(collection(firestore, `Instances/${AppSettings.Name}/StudyTips`), studyTip);
                studyTip!.ID = newStudyTipRef.id;
                await updateDoc(newStudyTipRef, { ID: newStudyTipRef.id })

                if (studyTip?.HasImage && file) {
                    const bytes = await file.arrayBuffer();
                    // PATH GUARDADO STORAGE
                    const path = `Clients/${AppSettings.Name}/StudyTips/${studyTip.ID}/${studyTip.ID}`;
                    const fileRef = ref(storage, path);
                    // GUARDAR IMAGEN EN STORAGE
                    await uploadBytes(fileRef, bytes, type);
                    const fullPath = await getDownloadUrl(fileRef.fullPath);
                    await updateDoc(newStudyTipRef, { Img: fullPath });
                    studyTip.Img = fullPath;
                }
                tempData.studyTips?.push(studyTip!);
            }
            dispatch({
                type: "set",
                values: tempData,
            })
            setStudyTip(undefined);
            setFile(undefined);
        } catch (e) {
            console.log('Error guardado studyTips: ', e)
        }
    }

    /**
     * Verificar que se haya cargado una imagen
     * @param e evento de formulario
     */
    const handleFile = async (e: React.FormEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files || [];
        if (files.length > 0) {
            setFile(files[0]);
            let url = URL.createObjectURL(files[0]);
            let type;

            if (files[0].name.endsWith("jpg") || files[0].name.endsWith("jpeg") || files[0].name.endsWith("jfif")) {
                type = {
                    contentType: "image/jpeg",
                };
            } else if (files[0].name.endsWith("png")) {
                type = {
                    contentType: "image/png",
                };
            } else if (files[0].name.endsWith("tif") || files[0].name.endsWith("tiff")) {
                type = {
                    contentType: "image/tiff",
                };
            } else if (files[0].name.endsWith("svg")) {
                type = {
                    contentType: "image/svg+xml",
                };
            } else if (files[0].name.endsWith("webp")) {
                type = {
                    contentType: "image/webp",
                };
            } else if (files[0].name.endsWith("bmp")) {
                type = {
                    contentType: "image/bmp",
                };
            } else {
                showMessage({
                    severity: 'error',
                    text: 'Formato de imagen no compatible'
                });
                setFile(undefined);
                return;
            }
            setType(type);
            studyTip && setStudyTip({
                ...studyTip,
                Img: url
            });
        } else {
            studyTip && setStudyTip({
                ...studyTip,
                Img: ''
            });
            setFile(undefined);
        }
    };

    /**
     * Eliminar study tip e imagen
     */
    const handleDelete = async () => {
        const tempData = { ...state };
        const refStudyTipDelete = doc(firestore, `Instances/${AppSettings.Name}/StudyTips/${openDelete?.ID}`);
        const index = findIndex(tempData.studyTips, { ID: openDelete?.ID });
        if (index !== -1) {
            tempData.studyTips?.splice(index, 1);
        }
        if (openDelete?.Img !== '') {
            const folderRef = ref(storage, `Clients/${AppSettings.Name}/StudyTips/${openDelete?.ID}/${openDelete?.ID}`);
            await deleteObject(folderRef);
        }

        await deleteDoc(refStudyTipDelete);
        setOpenDelete(undefined);
    }

    const handleClose = () => {
        setStudyTip(undefined);
        setOpenDelete(undefined);
        setFile(undefined);
    }

    if (studyTips)
        return <StudyTipsView
            columns={columns}
            rows={studyTips}
            setStudyTip={setStudyTip}
            studyTip={studyTip}
            handleSave={handleSave}
            handleFile={handleFile}
            handleDelete={handleDelete}
            openDelete={openDelete}
            file={file}
            handleClose={handleClose}
        />
    else
        return <Grid container>
            <Grid item xs={12} sx={{ py: 1 }}>
                <LinearProgress color="secondary" />
            </Grid>
        </Grid>
}
export default StudyTips;