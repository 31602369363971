import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, TextField, Tooltip, Typography } from "@mui/material";
import { Check, Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { regExpConsecutiveChars, regExpLowerHighCase, regExpMinCharLength, regExpMinNumbers, specialCharRegExp } from "../../Config/constants";
import { useState } from "react";

interface Props {
    open: boolean;
    handleClose: () => void;
    paws: { paw: string, paw2: string };
    setPaws: any;
    handleSave: () => void;
    visible: boolean;
    setVisible: (e: boolean) => void;
    loading: boolean;
    canSave: boolean;
    ConsecutiveChars: (e: string) => boolean;
}

const PasswordModalView: React.FC<Props> = ({ open, handleClose, paws, setPaws, handleSave, visible, setVisible, loading, canSave, ConsecutiveChars }) => {
    const [openTip, setOpenTip] = useState<boolean>(false);
    const [openTip2, setOpenTip2] = useState<boolean>(false);

    /**
    * Mostrar al usuario que requisitos de contraseña ha cumplido 
    * @returns List items con checks cumplidos
    */
    const passwordCheckList = () => {
        return <List dense disablePadding>
            <ListItem disablePadding>
                <ListItemIcon>
                    {regExpMinCharLength.test(paws.paw) ? <Check sx={{ color: '#15c70c' }} /> : <Close sx={{ color: '#FA0000' }} />}
                </ListItemIcon>
                <ListItemText disableTypography sx={{ fontSize: '12px' }}>
                    La contraseña debe tener una longitud de al menos 8 caracteres
                </ListItemText>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    {regExpMinNumbers.test(paws.paw) ? <Check sx={{ color: '#15c70c' }} /> : <Close sx={{ color: '#FA0000' }} />}
                </ListItemIcon>
                <ListItemText disableTypography sx={{ fontSize: '12px' }}>
                    Debe contener al menos 2 números
                </ListItemText>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    {specialCharRegExp.test(paws.paw) ? <Check sx={{ color: '#15c70c' }} /> : <Close sx={{ color: '#FA0000' }} />}
                </ListItemIcon>
                <ListItemText disableTypography sx={{ fontSize: '12px' }}>
                    Debe contener al menos un caracter especial
                </ListItemText>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    {regExpLowerHighCase.test(paws.paw) ? <Check sx={{ color: '#15c70c' }} /> : <Close sx={{ color: '#FA0000' }} />}
                </ListItemIcon>
                <ListItemText disableTypography sx={{ fontSize: '12px' }}>
                    Debe contaner al menos una letra mayúscula y una minúscula
                </ListItemText>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    {regExpConsecutiveChars.test(paws.paw) ? <Check sx={{ color: '#15c70c' }} /> : <Close sx={{ color: '#FA0000' }} />}
                </ListItemIcon>
                <ListItemText disableTypography sx={{ fontSize: '12px' }}>
                    No debe contener caracteres repetidos como "aaa" o "111"
                </ListItemText>
            </ListItem>
            <ListItem disablePadding>
                <ListItemIcon>
                    {ConsecutiveChars(paws.paw) ? <Check sx={{ color: '#15c70c' }} /> : <Close sx={{ color: '#FA0000' }} />}
                </ListItemIcon>
                <ListItemText disableTypography sx={{ fontSize: '12px' }}>
                    No debe contener caracteres secuenciales como "123" "abc" "fgh"
                </ListItemText>
            </ListItem>
        </List>
    }

    return (
        <Dialog open={open} onClose={(e, reason) => { !reason ? handleClose() : null }}
            maxWidth='sm'
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: '5px',
                }
            }}
        >
            <DialogTitle>
                <Typography>
                    Su contraseña ha vencido, por favor ingrese una nueva contraseña.
                </Typography>
                <Typography variant="body2" fontWeight='bold'>
                    Nota: No debe ser la misma a alguna de sus 5 contraseñas anteriores.
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: '1px' }}>
                    <Grid item xs={12}>
                        <Tooltip
                            title={passwordCheckList()}
                            open={openTip && !canSave}
                            placement="top"
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: '#7594B1'
                                    }
                                },
                                arrow: {
                                    sx: {
                                        color: '#7594B1',
                                    }
                                }
                            }}
                            arrow
                        >
                            <TextField
                                label='Contraseña'
                                value={paws.paw}
                                onChange={(e) => {
                                    setPaws({ ...paws, paw: e.target.value })
                                }}
                                autoComplete='off'
                                onFocus={() => setOpenTip(true)}
                                onBlur={() => setOpenTip(false)}
                                fullWidth
                                size='small'
                                type={visible ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge='end' onClick={() => { setVisible(!visible) }}>
                                                {visible ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip
                            title={'Verifique que ambas contraseñas coincidan'}
                            open={openTip2 && paws.paw !== paws.paw2}
                            placement="top"
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: '#7594B1'
                                    }
                                },
                                arrow: {
                                    sx: {
                                        color: '#7594B1',
                                    }
                                }
                            }}
                            arrow
                        >
                            <TextField
                                label='Confirmar contraseña'
                                value={paws.paw2}
                                onChange={(e) => {
                                    setPaws({ ...paws, paw2: e.target.value })
                                }}
                                onFocus={() => setOpenTip2(true)}
                                onBlur={() => setOpenTip2(false)}
                                autoComplete='off'
                                fullWidth
                                size='small'
                                type={visible ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge='end' onClick={() => { setVisible(!visible) }}>
                                                {visible ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='contained'
                    onClick={handleSave}
                    disabled={!canSave}
                >
                    {loading ? 'Cargando...' : 'Actualizar contraseña'}
                </Button>
            </DialogActions>
        </Dialog>

    )
}
export default PasswordModalView;