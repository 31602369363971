import { Remove } from "@mui/icons-material";
import {
	Button,
	CircularProgress,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	SwipeableDrawer,
	Toolbar,
	Typography,
	useMediaQuery
} from "@mui/material";
import { useState } from "react";
import { ActivityMaterial } from "../../../../interfaces";
import { theme } from "../../../SelectParticipants/Instances/DHL/Components/CustomTable/style";
import useStyles from "./style";

interface Props {
	activities: ActivityMaterial[];
	selectedActivity: ActivityMaterial | undefined;
	setSelectedActivity: (activity: ActivityMaterial | undefined) => void;
	selectedUrl: string;
	loading: boolean;
}

const ActivitiesView: React.FC<Props> = ({
	activities,
	selectedActivity,
	setSelectedActivity,
	selectedUrl,
	loading,
}) => {
	const [mobileOpen, setMobileOpen] = useState(false);
	const classes = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	let currentModuleName = "";;

	if (activities.length === 0) {
		return (
			<div className={classes.root}>
				<Typography fontWeight='bold' variant='h5'>
					No hay actividades disponibles
				</Typography>
			</div>
		);
	}

	const Mobile = () => {
		return (
			<Grid container justifyContent='center' padding={1}>
				<Grid item xs={12} marginBottom={1}>
					{/* <IconButton
						onClick={handleDrawerToggle}
						edge="start"
						sx={{ color: '#000', ml: 0 }}
					>
						<Menu sx={{ color: '#000' }} />
					</IconButton> */}
					<Button variant="contained" fullWidth onClick={() => { setMobileOpen(true) }}>
						Ver actividades
					</Button>
				</Grid>
				<Grid item xs={12}>
					{/* <Box sx={{ display: 'flex', }}> */}
					<SwipeableDrawer
						onOpen={() => { setMobileOpen(true) }}
						variant="temporary"
						open={mobileOpen}
						onClose={() => { setMobileOpen(false) }}
						ModalProps={{
							keepMounted: true,
						}}
						sx={{ display: 'block', '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 } }}
					>
						<Toolbar />
						<Divider />
						<List dense className={classes.list}>
							{activities.map((activity) => {
								let block = null;
								if (activity.ModuleName !== currentModuleName) {
									currentModuleName = activity.ModuleName;
									block = <div className={classes.blockDivider}>{currentModuleName}</div>;
								}
								return (
									<ListItem key={activity.ID} sx={{ padding: 0 }}>
										<Grid container direction={"column"}>
											{block}
											<ListItemButton
												className={
													selectedActivity && activity.ID === selectedActivity.ID
														? classes.selectedMaterial
														: classes.unselectedMaterial
												}
												onClick={() => {
													setSelectedActivity(activity);
													setMobileOpen(false);
												}}>
												<ListItemText
													primary={activity.TopicName}
													secondary={activity.Name}
												/>
												{
													selectedActivity && selectedActivity.ID === activity.ID ?
														<Remove sx={{ color: 'red' }} />
														:
														null
												}
											</ListItemButton>
										</Grid>
									</ListItem>
								);
							})}
						</List>
					</SwipeableDrawer>
					<Grid item xs={12} component="main">
						{
							loading && (
								<CircularProgress className={classes.progress} size={50} />
							)
						}
						{
							!loading && selectedActivity ?
								<div className={classes.pdf} >
									<iframe
										title={selectedActivity.Name}
										src={`${selectedUrl}#view=FitH`}
									/>
								</div>
								:
								<Typography variant='h6'>Selecciona una actividad</Typography>
						}
					</Grid>
					{/* </Box> */}
				</Grid>
			</Grid >
		)
	}

	return (
		isMobile ?
			Mobile()
			:
			(
				<Grid container spacing={2}>
					<Grid item xs={3} className={classes.list}>
						<List dense>
							{activities.map((activity) => {
								let block = null;
								if (activity.ModuleName !== currentModuleName) {
									currentModuleName = activity.ModuleName;
									block = <div className={classes.blockDivider}>{currentModuleName}</div>;
								}
								return (
									<ListItem key={activity.ID} sx={{ padding: 0 }}>
										<Grid container direction={"column"}>
											{block}
											<ListItemButton
												className={
													selectedActivity && activity.ID === selectedActivity.ID
														? classes.selectedMaterial
														: classes.unselectedMaterial
												}
												onClick={() => setSelectedActivity(activity)}>
												<ListItemText
													primary={activity.TopicName}
													secondary={activity.Name}
												/>
												{
													selectedActivity && selectedActivity.ID === activity.ID ?
														<Remove sx={{ color: 'red' }} />
														:
														null
												}
											</ListItemButton>
										</Grid>
									</ListItem>
								);
							})}
						</List>
					</Grid>
					<Grid item xs={9}>
						{
							loading && (
								<CircularProgress className={classes.progress} size={50} />
							)
						}
						{
							!loading && selectedActivity ?
								<div className={classes.pdf} >
									<iframe
										title={selectedActivity.Name}
										src={`${selectedUrl}#view=FitH`}
									/>
								</div>
								:
								<Typography variant='h6'>Selecciona una actividad</Typography>
						}
					</Grid>
				</Grid>
			)
	);
};

export default ActivitiesView;
