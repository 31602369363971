import React from "react";
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { StudyTip } from '../../../../../interfaces/course';
import BackdropComponent from '../../../../../components/Backdrop'
import { makeStyles } from "@mui/styles";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

interface Props {
  studyTips: StudyTip[]
};

const Carousel: React.FC<Props> = ({ studyTips }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = studyTips.length;
  const theme = useTheme();

  const useStyles = makeStyles({
    dotActive: {
      backgroundColor: "#D40511"
    }
  });

  const color = useStyles();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  if (studyTips && studyTips.length > 0)
    return (
      <Box sx={{ flexGrow: 1 }} >
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {studyTips.map((step, index) => (
            <div key={step.Title}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box
                      component="img"
                      sx={{
                        // height: 255,
                        maxHeight: '250px',
                        display: 'block',
                        objectFit: 'contain',
                        // maxWidth: 400,
                        overflow: 'hidden',
                        width: '100%',
                      }}
                      src={step.Img !== "" ? step.Img : ""}
                      alt={step.Img !== "" ? step.Img : ""}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Paper square elevation={0}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        pl: 2,
                        bgcolor: 'background.default',
                      }}
                    >
                      <Grid container spacing={5} p={3}>
                        <Grid item xs={12}>
                          <Typography variant="h5">{step.Title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant='caption'
                            align="justify"
                            sx={{ fontSize: 15 }}>
                            {step.Description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          color="secondary"
          sx={{ color: "#D40511" }}
          variant="dots"
          classes={{
            dotActive: color.dotActive
          }}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              color="secondary"
            >
              Siguiente
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small" color="secondary" onClick={handleBack} disabled={activeStep === 0}>
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Anterior
            </Button>
          }
        />
      </Box>
    )
  else return <BackdropComponent />
};

export default Carousel;
