import React from "react";
import { Card, Grid, Typography } from "@mui/material";
import classNames from "classnames";
import { useAppContext } from "../../../auth/appContext";
import useStyles from "./style";
import { Contact } from "../../../interfaces";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface Props {
    item: Contact,
    borderLeftColor?: string
}
const ContactCard: React.FC<Props> = ({
    item,
    borderLeftColor
}) => {
    const { AppSettings } = useAppContext();
    const classes = useStyles({ AppSettings, borderLeftColor });
    return (
        <Card
            className={classNames(classes.card, classes.cardBorder)}
            sx={{ marginBottom: 1 }}
        >
            <Grid
                container
                className={classes.actualCourseContainer}
                direction='column'
            >
                <Typography marginBottom={1} display="flex" alignItems="center">
                    <strong>{item.Name}</strong>
                </Typography>
                <Typography
                    className={classes.contactInfo}
                    fontWeight="500"
                    color="#A6ACBE">
                    <LocationOnIcon className={classes.contactInfoIcon} />
                    {item.Region}
                </Typography>
                {item.Email &&
                    <Typography
                        className={classes.contactInfo}
                        fontWeight="500"
                        color="#A6ACBE">
                        <EmailIcon className={classes.contactInfoIcon} />
                        {item.Email}
                    </Typography>}
                {item.Tel &&
                    <Typography
                        className={classes.contactInfo}
                        fontWeight="500"
                        color="#A6ACBE">
                        <PhoneIcon className={classes.contactInfoIcon} />
                        {item.Tel}
                    </Typography>}
            </Grid>
        </Card>
    );
};

export default ContactCard;
