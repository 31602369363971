import React, { Suspense, useEffect, useState } from "react";
import { Grid, Theme, useTheme } from "@mui/material";

import { useAppContext } from "../../auth/appContext";
import * as LoginForm from "./Instances";
import { makeStyles } from "@mui/styles";

const LoginView: React.FC = () => {
	const { AppSettings } = useAppContext();
	const useStyles = makeStyles((theme: Theme) => ({
		size: {
			[theme.breakpoints.only("xs")]: {
				backgroundColor: "red",
			},
			[theme.breakpoints.only("sm")]: {
				backgroundColor: "brown",
			},
			[theme.breakpoints.only("md")]: {
				backgroundColor: "white",
			},
			[theme.breakpoints.only("lg")]: {
				backgroundColor: "blue",
			},
			[theme.breakpoints.only("xl")]: {
				backgroundColor: "black",
			},
		},
		container: {
			width: "100%",
			height: "100vh",
			display: "flex",
			[theme.breakpoints.only("sm")]: {
				flexDirection: "column",
			},
		},
		mainContainerLogin: {
			backgroundColor: AppSettings.DesignSettings.Palette.primary.main,
			height: "100%",
			display: "flex",
			flexDirection: "column",
		},
		containerCustomerImg: {
			width: "53vw",
			position: "relative",
			display: "flex",
			justifyContent: "center",
			backgroundColor: AppSettings.DesignSettings.Palette.primary.main,
			borderRadius: "21px",
			padding: "30px 0px 30px 0px",
		},
		containerItemLogin: {
			display: "flex",
			justifyContent: "flex-end",
			paddingLeft: "15px",
			[theme.breakpoints.only("sm")]: {
				paddingLeft: "65px",
				// fontSize: "10px",
			},
		},
		divContainerWhite: {
			height: "350px",
			width: "34.65vw",
			display: "flex",
			justifyContent: "center",
			backgroundColor: "white",
			borderRadius: "31px 0px 0px 31px",
		},
		divContainerWhiteSmall: {
			height: "350px",
			width: "80%",
			display: "flex",
			justifyContent: "center",
			backgroundColor: "white",
			borderRadius: "31px 31px 31px 31px",
		},
		divContainerLoginComp: {
			display: "flex",
			justifyContent: "flex-start",
			width: "100%",
		},
		containerLeftItemAndImgSoftk: {
			backgroundColor: AppSettings.DesignSettings.Palette.primary.main,
			height: "100%",
			justifyContent: "flex-start",
			display: "flex",
			flexDirection: "row",
		},
		containerItemAndImg: {
			height: "100%",
			display: "flex",
			flexDirection: "row",
		},
		itemLeftItemColor: {
			backgroundColor: "white",
			height: "100%",
			justifyContent: "flex-start",
			borderRadius: "21px 0px 0px 0px",
		},
		divLeftItemColor: {
			width: "100%",
			backgroundColor: AppSettings.DesignSettings.Palette.primary.main,
			height: "100%",
			borderRadius: "21px 21px 21px 0px",
		},
		itemContainerImgSftk: {
			backgroundColor: "white",
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "flex-end",
		},
		mainContainerRightImg: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			flexFlow: "column wrap",
		},
		divRightImg: {
			width: "100%",
			height: "100vh",
			overflow: "hidden",
		},
	}));

	const classes = useStyles();
	const theme = useTheme();

	const [isSmallView, setIsSmallView] = useState<boolean>(false);

	const handleResize = () => {
		if (window.outerWidth <= 600) {
			setIsSmallView(true);
		} else if (window.outerWidth > 600) {
			setIsSmallView(false);
		}
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const renderLoginForm = () => {
		switch (AppSettings.Name) {
			case 'DHL':
				return <Suspense fallback={<div>Loading...</div>}><LoginForm.DHL /></Suspense>
			case 'Softtek':
			case 'DEV':
				return <Suspense fallback={<div>Loading...</div>}><LoginForm.Softtek /></Suspense>
			default:
				return <Suspense fallback={<div>Loading...</div>}><LoginForm.Default /></Suspense>
		}
	}

	return (
		<>
			{isSmallView ? (
				<Grid
					container
					style={{
						backgroundColor: theme.palette.primary.main,
						height: "100vh",
					}}
					direction='column'
					justifyContent='space-between'
					alignItems='center'>
					<Grid item style={{}}>
						<img src={`${AppSettings?.DesignSettings.Images.Logo}`} width={"100%"} alt='logo' />
					</Grid>
					<Grid item style={{}} className={classes.divContainerWhiteSmall}>
						{renderLoginForm()}
					</Grid>
					<Grid item style={{}}>
						<img
							src={`${AppSettings?.DesignSettings.Images.InnovationLogo}`}
							width='100%'
							height='auto'
							alt='innovation logo'
						/>
					</Grid>
				</Grid>
			) : (
				<Grid container className={classes.container}>
					{/* container parte izq login */}
					<Grid
						item
						// spacing={0}
						justifyContent='flex-start'
						alignItems='stretch'
						className={classes.mainContainerLogin}
						xs={1}
						sm={5}
						md={4}
						lg={3}
						xl={3}>
						{/* Imagen DHL */}
						<Grid item sm={1}>
							<div className={classes.containerCustomerImg}>
								{/* <div className={classes.size}>size</div> */}
								<img
									src={`${AppSettings?.DesignSettings.Images.Logo}`}
									width={"40%"}
									alt='logo dhl'
								/>
							</div>
						</Grid>
						{/* Container Item Iniciar Sesion */}
						<Grid item xs={0}>
							{/* Recuadro blanco para botones */}
							<Grid item className={classes.containerItemLogin} xs={12} sm={12}>
								<div className={classes.divContainerWhite}>
									<div className={classes.divContainerLoginComp}>
										{renderLoginForm()}
									</div>
								</div>
							</Grid>
						</Grid>
						{/* Item para cuadro amarillo y logo softtek */}
						<Grid
							item
							xs={0}
							// direction="row"
							justifyContent='flex-start'
							alignItems='stretch'
							className={classes.containerLeftItemAndImgSoftk}>
							<Grid
								container
								// direction="row"
								justifyContent='flex-start'
								alignItems='stretch'
								className={classes.containerItemAndImg}>
								<Grid item className={classes.itemLeftItemColor} xs={4}>
									<div className={classes.divLeftItemColor}></div>
								</Grid>
								<Grid item className={classes.itemContainerImgSftk} xs={8}>
									<img
										src={`https://firebasestorage.googleapis.com/v0/b/virtualuniversity-b7176.appspot.com/o/Clients%2FSofttek%2FImages%2FInnovationLogo.png?alt=media&token=c1234318-fef7-440c-bb05-1da7c9b10c38`}
										width='100%'
										height='auto'
										alt='softtek'
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{/* container parte derecha imagen */}
					<Grid
						item
						className={classes.mainContainerRightImg}
						xs={11}
						sm={7}
						md={8}
						lg={9}
						xl={9}>
						<div className={classes.divRightImg}>
							<img
								src={`${AppSettings?.DesignSettings.Images.LoginPersonal}`}
								width='100%'
								height='100%'
								alt='login personal'
							/>
						</div>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default LoginView;
