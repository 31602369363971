import { CircularProgress, lighten } from "@mui/material"
import { useAppContext } from "../../auth/appContext"

/**
 * Obtener botón principal de acciones que no sean modales
 * @param instance nombre de instancia
 * @returns boton de acción principal (no modales o dialogos)
 */
export const getButtonDefault = (instance: string): {
    variant: 'contained' | 'outlined' | 'text',
    color: 'primary' | 'secondary'
} => {
    switch (instance) {
        case 'DHL':
            return {
                variant: "contained", color: "secondary"
            }
        default:
            return {
                variant: "contained", color: "primary"
            }
    }
}

/**
   * Obtiene boton main
   * @param instance nombre de instancia
   * @returns color/variante con estilo de acción principal
   */
export const getButtonMainAction = (instance: string): {
    variant: 'contained' | 'outlined' | 'text',
    color: 'primary' | 'secondary'
} => {
    switch (instance) {
        case 'DHL':
            return {
                variant: "outlined", color: "secondary"
            }
        default:
            return {
                variant: "contained", color: "primary"
            }
    }
}

/**
 * Obtiene boton secondary
 * @param instance nombre de instancia
 * @returns color/variante con estilo de accion secundaria
 */
export const getButtonSecondaryAction = (instance: string): {
    variant: 'contained' | 'outlined' | 'text',
    color: 'primary' | 'secondary'
} => {
    switch (instance) {
        case 'DHL':
            return {
                variant: "text", color: "secondary"
            }
        default:
            return {
                variant: "contained", color: "secondary"
            }
    }
}

/**
 * Obtener color de progress
 * @param instance nombre de instancia
 * @returns progreso de color de instancia
 */
export const CircularProgressHelper = (instance: string) => {
    switch (instance) {
        case 'DHL':
            return (<CircularProgress color="secondary" />)
        default:
            return (<CircularProgress color="primary" />)
    }
}