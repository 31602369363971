import { useAppContext } from "../../../auth/appContext";
import UserProfileButton_Default from "./Instances/Default";
import UserProfileButton_DHL from "./Instances/DHL";

const UserProfileButton = () => {
	const { AppSettings } = useAppContext();

	switch (AppSettings.Name) {
		case 'DHL':
		case 'DHL2':
			return <UserProfileButton_DHL />;
		default:
			return <UserProfileButton_Default />;
	}
};

export default UserProfileButton;
