import { createTheme, darken, lighten, Theme } from "@mui/material/styles";
import { ClientSettings } from "../../interfaces";
import { theme } from "../../pages/SelectParticipants/Instances/DHL/Components/CustomTable/style";

export const getTema = (data: ClientSettings): Theme => {
    switch (data.Name) {
        case 'DHL':
        case 'DHL2':
            return createTheme({
                palette: data.DesignSettings.Palette,
                typography: {
                    fontFamily: data.FontFamily ?? 'Arial,Helvetica,sans-serif'
                },
                shape: {
                    borderRadius: data.DesignSettings.borderRadius ?? 0,
                },
                components: {
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                borderRadius: data.DesignSettings.borderRadius ?? 0,
                                textTransform: 'none',
                            },
                            contained: {
                                backgroundColor: data.DesignSettings.Palette!['secondary']['main'],
                                color: '#ffffff',
                                '&:hover': {
                                    backgroundColor: darken(data.DesignSettings.Palette!.secondary.main, 0.1),
                                }
                            },
                            outlined: {
                                borderColor: data.DesignSettings.Palette!.secondary.main,
                                color: data.DesignSettings.Palette!.secondary.main,
                                '&:hover': {
                                    backgroundColor: lighten(data.DesignSettings.Palette!.secondary.main, 0.9),
                                    borderColor: data.DesignSettings.Palette!.secondary.main,
                                }
                            },
                            text: {
                                color: data.DesignSettings.Palette!.secondary.main,
                                '&:hover': {
                                    backgroundColor: lighten(data.DesignSettings.Palette!.secondary.main, 0.9),
                                }
                            },
                        },
                    },
                    MuiTextField: {
                        styleOverrides: {
                            root: {
                                color: '#000000',
                                '& label.Mui-focused': {
                                    color: '#000000',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: '#000000',
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: 'gray',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#000000',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#000000',
                                    },
                                },
                            }
                        }
                    },
                    MuiChip: {
                        styleOverrides: {
                            root: {
                                color: '#000000',
                                borderRadius: '3px',
                                borderColor: '#000000',
                            },
                            colorPrimary: {
                                color: '#ffffff',
                                backgroundColor: data.DesignSettings.Palette!.secondary.main,
                                borderColor: data.DesignSettings.Palette!.secondary.main,
                                borderRadius: '4px',
                                // '&:hover': {
                                //     backgroundColor: 'inherit',
                                //     color: 'inherit',
                                //     borderColor: 'inherit',
                                // }
                            },
                            outlinedPrimary: {
                                color: data.DesignSettings.Palette!.secondary.main,
                                backgroundColor: '#ffffff',
                                borderColor: data.DesignSettings.Palette!.secondary.main,
                            },
                            deleteIconOutlinedColorPrimary: {
                                color: "inherit",
                                outlineColor: '#000000',
                                borderColor: '#0000000',
                                '&:hover': {
                                    color: lighten(data.DesignSettings.Palette!.secondary.main, 0.3)
                                },
                                '&:focus': {
                                    color: lighten(data.DesignSettings.Palette!.secondary.main, 0.6)
                                },
                                '&:active': {
                                    color: lighten(data.DesignSettings.Palette!.secondary.main, 0.6)
                                }
                            },
                            deleteIconColorPrimary: {
                                color: 'inherit',
                                '&:hover': {
                                    color: lighten('#000000', 0.9)
                                }
                            },
                            colorSecondary: {
                                color: '#ffffff',
                                backgroundColor: lighten('#000000', 0.2),
                                borderColor: 'inherit',
                                borderRadius: '4px',
                            },
                            outlinedSecondary: {
                                color: '#000000',
                                backgroundColor: '#ffffff',
                                borderColor: '#000000',
                            },
                            deleteIconOutlinedColorSecondary: {
                                color: "#000000",
                                outlineColor: '#000000',
                                borderColor: '#0000000',
                                '&:hover': {
                                    color: lighten('#000000', 0.3)
                                },
                                '&:focus': {
                                    color: lighten('#000000', 0.6)
                                },
                                '&:active': {
                                    color: lighten('#000000', 0.6)
                                }
                            },
                        }
                    },
                    MuiAlert: {
                        styleOverrides: {
                            filledInfo: {
                                color: '#000000',
                                backgroundColor: '#ffffff',
                                '& .MuiAlert-icon': {
                                    color: data.DesignSettings.Palette!.secondary.main,
                                },
                                '& .MuiAlert-action': {
                                    color: data.DesignSettings.Palette!.secondary.main,
                                }
                            },
                            outlinedInfo: {
                                color: '#000000',
                                backgroundColor: '#ffffff',
                                borderColor: 'inherit',
                                '& .MuiAlert-icon': {
                                    color: data.DesignSettings.Palette!.secondary.main,
                                },
                                '& .MuiAlert-action': {
                                    color: data.DesignSettings.Palette!.secondary.main,
                                }
                            },
                            filledError: {
                                color: '#ffffff',
                                backgroundColor: data.DesignSettings.Palette!.secondary.main,
                                borderColor: 'inherit'
                            },
                            outlinedError: {
                                color: data.DesignSettings.Palette!.secondary.main,
                                '& .MuiAlert-icon': {
                                    color: data.DesignSettings.Palette!.secondary.main,
                                },
                                '& .MuiAlert-action': {
                                    color: data.DesignSettings.Palette!.secondary.main,
                                }
                            },
                            filledSuccess: {
                                color: '#ffffff',
                                backgroundColor: data.DesignSettings.Palette!.grey['600'],
                            },
                            outlinedSuccess: {
                                color: data.DesignSettings.Palette!.grey['600'],
                                backgroundColor: '#ffffff',
                                borderColor: 'inherit',
                                '& .MuiAlert-icon': {
                                    color: data.DesignSettings.Palette!.grey['600']

                                }
                            },
                            filledWarning: {
                                color: '#000000',
                                background: "linear-gradient(90deg,#fc0 0,#fc0 48%,#ffe57f 70%,#fff0b2)",
                                '& .MuiAlert-action': {
                                    color: data.DesignSettings.Palette!.secondary.main,
                                }
                            },
                            outlinedWarning: {
                                color: '#000000',
                                backgroundColor: 'inherit',
                                borderColor: data.DesignSettings.Palette!.primary.main,
                                '& .MuiAlert-action': {
                                    color: data.DesignSettings.Palette!.primary.main,
                                }
                            },
                            standardWarning: {
                                color: '#000000',
                                background: "linear-gradient(90deg,#fc0 0,#fc0 48%,#ffe57f 70%,#fff0b2)",
                                '& .MuiAlert-action': {
                                    color: data.DesignSettings.Palette!.secondary.main,
                                },
                                '& .MuiAlert-icon': {
                                    color: '#000000',
                                }
                            },
                            standardError: {
                                color: '#ffffff',
                                backgroundColor: data.DesignSettings.Palette!.secondary.main,
                                borderColor: 'inherit',
                                '& .MuiAlert-icon': {
                                    color: 'inherit'
                                },
                            },
                            standardInfo: {
                                color: '#000000',
                                backgroundColor: '#ffffff',
                                '& .MuiAlert-icon': {
                                    color: data.DesignSettings.Palette!.secondary.main,
                                },
                                '& .MuiAlert-action': {
                                    color: data.DesignSettings.Palette!.secondary.main,
                                }
                            },
                            standardSuccess: {
                                color: '#ffffff',
                                backgroundColor: data.DesignSettings.Palette!.grey['800'],
                                '& .MuiAlert-icon': {
                                    color: 'inherit',
                                }
                            },
                        }
                    },
                    MuiInputLabel: {
                        styleOverrides: {
                            root: {
                                color: '#000000',
                                '& label.Mui-focused': {
                                    color: '#000000',
                                },
                            }
                        }
                    },
                    MuiFormLabel: {
                        styleOverrides: {
                            root: {
                                '&.Mui-focused': {
                                    color: '#000000 !important'
                                }
                            }
                        }
                    },
                    MuiButtonBase: {
                        styleOverrides: {
                            root: {
                                '&.Mui-selected': {
                                    backgroundColor: 'inherit !important'
                                },
                            }
                        }
                    },
                    MuiOutlinedInput: {
                        styleOverrides: {
                            notchedOutline: {
                                borderColor: '#000000 !important'
                            }
                        }
                    },
                    MuiFormControlLabel: {
                        styleOverrides: {
                            root: {
                                "& .MuiSvgIcon-root": {
                                    color: "#000000"
                                }
                            }
                        }
                    },
                    MuiCssBaseline: {
                        styleOverrides: {
                            body: {
                                "*::-webkit-scrollbar": {
                                    width: "0.3em",
                                },
                                "*::-webkit-scrollbar-track": {
                                    "WebkitBoxShadow": "inset 0 0 6px rgba(0,0,0,0.00)",
                                },
                                "*::-webkit-scrollbar-thumb": {
                                    backgroundColor: data.DesignSettings.Palette!.grey['500'],
                                    outline: `1px solid ${data.DesignSettings.Palette!.grey['500']}`,
                                    borderRadius: "14px",
                                },
                            },
                        },
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            root: {
                                width: 42,
                                height: 53 / 2,
                                padding: 0,
                            },
                            switchBase: {
                                padding: 0,
                                margin: 2,
                                transitionDuration: "400ms",
                                "&.Mui-checked": {
                                    transform: "translateX(16px)",
                                    color: "#fff",
                                    "& + .MuiSwitch-track": {
                                        backgroundColor: "red",
                                        opacity: 1,
                                        border: 0
                                    },
                                    "&.Mui-disabled + .MuiSwitch-track": {
                                        opacity: 0.5
                                    }
                                },
                                "&.Mui-focusVisible .MuiSwitch-thumb": {
                                    color: "#33cf4d",
                                    border: "6px solid #000000"
                                },
                            },
                            thumb: {
                                boxSizing: "border-box",
                                width: 22,
                                height: 22
                            },
                            track: {
                                borderRadius: 13,
                                backgroundColor: "#fff",
                                border: "1px solid #000000",
                                transition: theme.transitions.create(["background-color"], {
                                    duration: 500
                                })
                            },
                        }
                    },
                    MuiTableCell: {
                        styleOverrides: {
                            head: {
                                fontWeight: 'bold',
                                borderBottom: '0px solid #fff',
                                backgroundColor: 'transparent',
                            },
                            footer: {
                                borderTop: '4px solid red',
                            }
                        }
                    },
                    MuiTableRow: {
                        styleOverrides: {
                            root: {
                                '&:nth-of-type(odd)': {
                                    backgroundColor: '#fff',
                                    '&:hover': {
                                        transition: theme.transitions.create(["background-color"], {
                                            duration: 200
                                        }),
                                        backgroundColor: data.DesignSettings.Palette!.grey['300'],
                                    },
                                },
                                '&:nth-of-type(even)': {
                                    backgroundColor: data.DesignSettings.Palette!.grey[200],
                                    '&:hover': {
                                        transition: theme.transitions.create(["background-color"], {
                                            duration: 200
                                        }),
                                        backgroundColor: data.DesignSettings.Palette!.grey['300'],
                                    },
                                },
                            }
                        }
                    },
                }
            });

        default:
            return createTheme({
                palette: data.DesignSettings.Palette,
                typography: {
                    fontFamily: data.FontFamily ?? 'Arial,Helvetica,sans-serif'
                },
                shape: {
                    borderRadius: data.DesignSettings.borderRadius ?? 0,
                },
                components: {
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                borderRadius: data.DesignSettings.borderRadius ?? 0
                            }
                        }
                    },
                    MuiCssBaseline: {
                        styleOverrides: {
                            body: {
                                "*::-webkit-scrollbar": {
                                    width: "0.3em",
                                },
                                "*::-webkit-scrollbar-track": {
                                    "WebkitBoxShadow": "inset 0 0 6px rgba(0,0,0,0.00)",
                                },
                                "*::-webkit-scrollbar-thumb": {
                                    backgroundColor: data.DesignSettings.Palette['grey'][500],
                                    outline: `1px solid ${data.DesignSettings.Palette['grey'][500]}`,
                                    borderRadius: "14px",
                                },
                            },
                        },
                    },
                }
            });
    }

}